@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.post {
  &__set-reactions {
    position: relative;

    @include fontSemibold14;
    margin-bottom: 12px;
    box-shadow: $shadow;
    border-radius: 8px;

    box-shadow: none;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3px;

    @media screen and (min-width: 1100px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__set-reaction-container {
    padding: 4px;
    min-width: 33.3%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;

    transition: background-color 0.2s linear, border 0.2s linear;

    @media screen and (min-width: 1100px) {
      min-width: auto;
      min-width: 16.4%;
    }

    &--small {
      min-width: 0;
      flex-grow: 1;
    }

    &:hover {
      background-color: $accent-light;
      border: 1px solid $accent-add;
    }

    &--active {
      border: 1px solid $accent-main;
      background-color: $accent-light;

      &:hover {
        border: 1px solid $accent-main;
      }
    }
  }

  &__set-reaction {
    width: 32px;
    height: 32px;
  }

  &__set-reaction-text {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    @media screen and (min-width: 1100px) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__cooperation {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }

  &__reactions {
    display: flex;
    align-items: center;
  }

  &__no-reactions {
    font-size: 13px;
  }

  &__reactions-images {
    margin-right: 8px;
    display: flex;
  }

  &__reactions-img-container {
    margin-left: -8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $tags;
    border-radius: 50%;

    &:first-child {
      margin-left: 0;
    }
  }

  &__reactions-img {
    width: 20px;
    height: 20px;
  }

  &__reactions-peoples {
    position: relative;
    cursor: pointer;
    transition: 0.2s linear;

    display: none;

    @media screen and (min-width: 1100px) {
      display: flex;
      white-space: nowrap;
    }

    &::after {
      width: 0%;
      position: absolute;
      left: 0;
      bottom: 2px;
      content: '';
      height: 1px;
      background-color: transparent;
      transition: 0.2s linear;
    }

    &:hover {
      text-decoration: line;
      cursor: pointer;

      &::after {
        background-color: $text-dark;
        width: 100%;
      }
    }

    &--mobile {
      display: block;
      cursor: pointer;

      @media screen and (min-width: 1100px) {
        display: none;
      }
    }
  }

  &__tag-comment-share {
    display: flex;
    align-items: center;
    column-gap: 16px;

    @media (max-width: 1100px) {
      column-gap: 12px;
    }
  }

  &__tag-comment-share-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    transition: 0.2s linear;

    @media (min-width: 1100px) {
      line-height: 18px;
    }

    &.hover{
      cursor: default;
    }

    &.hover::after {
      width: 0%;
      position: absolute;
      left: 0;
      bottom: 2px;
      content: '';
      height: 1px;
      background-color: transparent;
      transition: 0.2s linear;
    }

    &.hover:hover {
      text-decoration: line;

      &.hover::after {
        background-color: $text-dark;
        width: 100%;
      }
    }

    & > img {
      width: 16px;
      height: 16px;
      margin-right: 4px;

      @media (min-width: 1100px) {
        display: none;
      }
    }

    & > svg {
      color: $text-middle;
      width: 16px;
      margin-right: 4px;

      @media (min-width: 1100px) {
        display: none;
      }
    }

    @media (max-width: 1100px) {
      font-size: 13px;
    }
  }

  &__tag-comment-share-item-text {
    display: none;
    margin-left: 4px;

    @media (min-width: 1100px) {
      display: flex;
      white-space: nowrap;
      font-size: 13px;
    }
  }

  &__tooltip-row {
    margin-bottom: 6px;
    display: flex;
    column-gap: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}