@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.accordion-main {
  &--border {
    & > .MuiPaper-root {
      border-bottom: 1px solid $stroke;
    }
  }

  &-rows {
    position: relative;
  }

  &__icon-container {
    width: 24px;
    height: 24px;
    top: 12px;
    right: 30px;
    position: absolute;

    @media screen and (min-width: 1100px) {
      right: 18px;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    position: absolute;
    color: #6c757d;
    z-index: 10;
    transition: transform 0.2s linear;
  }

  &__icon svg {
    position: absolute;
    transition: 0.2s linear;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 6px;
    padding-left: 24px;
    margin-bottom: 8px;
    position: relative;

    &:hover {
      background-color: $tags;
      cursor: pointer;
    }

    &:last-child {
      margin-bottom: 0;
    }

    // &>img {
    //   width: 24px;
    //   height: 24px;
    // }
  }

  & > .MuiButtonBase-root:hover {
    background-color: aqua;
  }

  &__button-archive {
    @include iconWithBackground;
    color: $text-middle;
    position: absolute;
    // right: 7.5px;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }

  &__button-edit{
    @include iconWithBackground;
    color: $text-middle;
    position: absolute;
    // right: 40px;
    right: 7.5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }
}

.MuiAccordion-region {
  > .MuiAccordionDetails-root {
    > .secondaryButton {
      width: 100%;
    }
  }
}
