@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.AddNewInterestModal {
  width: 559px;
  border-top: 1px solid $stroke;
  padding-top: 16px;

  >.customInput {
    margin-bottom: 16px;
  }

  &__uploadBtn {
    @include fontSemibold12;
    color: $text-middle;
    width: fit-content;
    padding: 2px;
    padding-left: 0;
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
    transition: $transition;

    &:hover,
    &:active {
      color: $accent-main;
      background-color: $accent-light;
    }
  }

  &__preview {
    &-title {
      @include fontSemibold14;
      color: $text-dark;
      margin-bottom: 8px;
    }

    &-content {
      @include fontRegular14;
      color: $text-dark;
      background-color: $tags;
      width: 300px;
      padding: 6px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
    }

    &-img {
      object-fit: contain;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
  }

  &__background {
    object-fit: cover;
    width: 559px;
    height: 132px;
    border-radius: 8px;
  }
}
 