@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.ModalHeader {
  @include fontSemibold18;
  color: $text-dark;
  min-width: 250px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;

  // &__postCreation {
  //   min-width: 560px;
  // }

  &--mainColor {
    color: $accent-main;
  }

  &--withPadding {
    margin-bottom: 0;
    padding: 16px 16px 8px;
    border-bottom: 1px solid $accent-main;

    &--withoutBorder {
      margin-bottom: 0;
      padding: 16px 24px 16px 56px;
    }
  }

  &__header {
    display: flex;
    text-align: center;
  }

  &__smallCross {

    svg.MuiSvgIcon-root {
      position: absolute;
      top: -12px;
      right: -12px;
      display: flex;
      width: 16px;
      height: 16px;
      color: $text-middle;
    }
  }

  &__cross {
    .MuiSvgIcon-root {
      color: $text-middle;
      transition: all .3s;
      cursor: pointer;

      &:hover {
        color: $accent-main;
        transform: rotate(180deg);
      }
    }
  }

  &__arrow {
    .MuiSvgIcon-root {
      color: $text-middle;
      transition: all .3s;
      cursor: pointer;
      margin-right: 12px;

      &:hover {
        color: $accent-main;
      }
    }
  }
}
