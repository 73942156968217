@import '../../../styles/utils/variables';

.tooltip-password-right {
  position: absolute;
  right: -202px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 12px;
  gap: 8px;
  width: 184px;
  height: 120px;
  background: $text-middle;
  border-radius: 4px;
  font-size: 12px;
  color: $white;
  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: -5px;
    top: 55px;
    background: $text-middle;
    border-radius: 3px;
    transform: rotate(45deg);
    z-index: 200;
  }
}
.tooltip-password-bottom {
  position: absolute;
  right: 0px;
  bottom: -150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 36px;
  gap: 8px;
  width: 302px;
  height: 120px;
  font-size: 12px;
  background: #f5f5f5;
  color: #6c757d;
  border-radius: 8px;
  z-index: 2000;
}
.infoIconWrapper {
  position: absolute;
  top: 8px;
  left: 13px;
  z-index: 2000;
}
