$white: #fff;
$tags: #F5F5F5;
$stroke: #E9E9E9;
$bg: #F8F8F8;

// accent 
$accent-light: #FBF5FF;
$accent-medium: #f8edfe;
$accent-add: #FADCFF;
$accent-main: #791186;
$accent-hover: #620C6D;

// text
$text-light: #A7A7A7;
$text-middle: #6C757D;
$text-dark: #363636;

// warning
$red: #FD2551;
$green: #28A745;

// post colors
$call-to-actions: #FFF5DB;
$petition: #DBF0FF;
$adv-events: #FFDBE1;
$survey: #DCFFDB;

//other
$transition: all .3s;
$shadow: 0px 4px 15px 0px #f2f2f2;

// old styles
$gray-text: #777777;
$dark: #193046;
