html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #363636;
  overflow: visible;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #fbf5ff;
}

::-webkit-scrollbar:hover {
  background-color: #fbf5ff;
}

::-webkit-scrollbar-thumb {
  background-color: #791186;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  min-height: 120px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

::selection {
  background: rgba(229, 196, 252, 0.75);
  color: #363636;
}
::-moz-selection {
  background: rgba(229, 196, 252, 0.75);
  color: #363636;
}
::-webkit-selection {
  background: rgba(229, 196, 252, 0.75);
  color: #363636;
}

.html-hidden {
  overflow: hidden !important;
}
