@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.customTextarea {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 24px;
  position: relative;

  &-postCreation {
    margin-bottom: 12px;
  }

  &-buttonContainer {
    position: absolute;
    display: flex;
    width: 90%;
    background-color: $white;
    border-radius: 0 0 8px 8px;
    padding: 0 8px;
    margin: 1px;
    transition: all .3s;
  }

  &-button {
    flex-direction: row;
    align-items: center;
    gap: 6px;
    bottom: 6px;
    left: 16px;
    padding: 2px 6px;

    @include fontSemibold12;
    color: $text-middle;
    cursor: pointer;
    border-radius: 4px;

    &--active {
      &:hover {
        background-color: $accent-light;
        color: $accent-hover;

        >svg {
          >path[class="changeColor"] {
            fill: $accent-hover;
          }
        }
      }
    }

    &--disabled {
      color: $text-light;

      &:hover {
          background-color: none;
          // color: $accent-hover;
      }
    }
  }

  &-max {
    flex-grow: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $text-middle;
    text-align: right;
    margin-top: 8px;
  }

  & label {
    display: flex;
    color: $text-middle;
    margin-bottom: 8px;
    font-size: 14px;
  }

  & textarea {
    width: 100%;
    height: 77px;
    border: 1px solid $stroke;
    border-radius: 8px;
    resize: none;
    padding: 16px;
    transition: all .3s;
    color: $accent-main;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: inherit;

    &::placeholder {
      color: $text-light;
    }

    &:hover {
      border-color: $accent-add;
    }

    &:focus {
      outline: none !important;
      border-color: $accent-add;
      background: $accent-light;

      &~.customTextarea-buttonContainer {
        background: $accent-light;
      }

      &~label {
        color: $text-dark;
      }
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: #fbf5ff;
      cursor: pointer;
    }

    &::-webkit-scrollbar:hover {
      background-color: #fbf5ff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #791186;
      border-radius: 10px;
      z-index: 111111;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--errors {
    // .customInput-inner {
    //   background-color: rgba(255, 246, 248, 1) !important;
    // }

    & textarea {
      background-color: rgba(255, 246, 248, 1) !important;

      &::placeholder {
        color: $text-light  !important;
      }
    }

    & textarea {
      border-color: #FFD7DF !important;

      &~.customTextarea-buttonContainer {
        background: rgba(255, 246, 248, 1) !important;
        bottom: 23px;
      }
    }
  }
}
