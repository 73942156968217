@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.MusicModal {
  &__container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1100px) {
      display: grid;
      grid-template-columns: minmax(652px, 712px) minmax(340px, 380px);
      grid-template-rows: repeat(2, auto);
      gap: 16px;
      // max-height: 600px;
    }
    .MuiTouchRipple-root {
      color: $accent-add;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    max-width: 300px;

    &-text {
      @include fontSemibold18;
      color: $text-dark;
    }

    > svg.fire {
      color: $red;
    }

    > svg.planet {
      color: $green;
    }

    > svg.rocket {
      color: $accent-main;
    }
  }

  &__topic {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }

  &__iframe {
    border-radius: 12px;
    border: 1px solid $accent-light;
    width: 100%;

    &-top50 {
      height: 240px;
    }

    &-trends {
      height: 180px;
    }

    &-choice {
      @media screen and (min-width: 1100px) {
        height: 288px;
      }
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    // width: 100%;
    width: 300px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    @media screen and (min-width: 1100px) {
      width: 100%;
      flex-flow: column nowrap;
    }
    .MuiTabs-root {
      min-width: 300px;
    }
    .emptyPlaylist {
      min-width: 300px;
      max-width: 300px;
      // height: 174px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (min-width: 1100px) {
        min-width: 652px;
        max-width: 712px;
        height: 174px;
      }

      &__text {
        @include fontRegular14;
        color: $text-light;
      }
    }

    .PlaylistView {
      padding: 8px;
      border-radius: 8px;
      margin: 0 4px;
      transition: $transition;

      &__cover {
        width: 140px;
        height: 140px;
        max-width: none;
        border-radius: 8px;
        transition: $transition;
      }

      &:hover {
        padding: 6px;
        cursor: pointer;
        background-color: $white;

        .PlaylistView__cover {
          width: 144px;
          height: 144px;
        }
      }

      &.Mui-selected {
        padding: 7px;
        border: 1px solid $accent-main;

        &:hover {
          padding: 5px;
        }
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  &__top50 {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  &__trends {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  &__choice {
    grid-column: 1/2;
    grid-row: 1/3;
  }
}
