.nav-side {
  min-width: 100%;
  display: none;
  flex-grow: 1;
  flex-shrink: 1;
  color: #363636;

  @media screen and (min-width: 1100px) {
    max-width: 275px;
    min-width: 275px;
    display: block;
    position: fixed;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 90px);
  }

  @media screen and (min-width: 1440px) {
    max-width: 324px;
    min-width: 324px;
    display: block;
    position: fixed;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 90px);
  }

  &__section {
    text-align: left;
    margin-bottom: 20px;
    padding: 12px;
    align-self: center;
    background-color: #fff;
    border-radius: 8px;

    @media screen and (min-width: 1100px) {
      padding: 10px;
    }

    @media screen and (min-width: 1440px) {
      padding: 12px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__section-info {
    position: relative;

    @media screen and (min-width: 1100px) {
      max-width: auto;
    }
  }

  &__title {
    padding: 10px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;

    @media screen and (min-width: 1100px) {
      padding: 0;
    }
  }

  & .accordion-main__title-container {
    @media screen and (min-width: 1100px) {
      width: 200px;
    }
  }
}
