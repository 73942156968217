@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.signUp-success {
  img {
    margin: 0 auto 24px auto;
  }

  &__title {
    @include fontSemibold32;
    text-align: center;
    background: linear-gradient(91deg, #833AB4 0%, #FD1D1D 50%, #FCB045 100%);
    background-clip: text;
    color: $accent-main;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 auto 24px auto;
  }

  &__subtitle {
    @include fontSemibold22;
    color: $text-dark;
    text-align: center;
    margin-bottom: 16px;

    >span {
      color: $accent-main;
    }
  }

  &__description {
    @include fontRegular18;
    color: $text-dark;
    text-align: center;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 1100px) {
    img {
      margin-bottom: 16px;
    }

    &__title {
      @include fontSemibold20;
      margin-bottom: 16px;
    }

    &__subtitle {
      @include fontSemibold16;
    }

    &__description {
      @include fontRegular14;
      margin-bottom: 0;
    }

    .mainButton {
      width: 279px;
    }
  }
}
