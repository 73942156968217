@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.TextareaAutosizeWrapper {
  position: relative;
  .TextareaAutosize {
    width: 100%;
    height: 40px;
    border: 1px solid $stroke;
    border-radius: 8px;
    resize: none;
    padding: 12px 16px;
    padding-right: 72px;
    transition: all 0.3s;
    color: $accent-main;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: inherit;
    transition: all 0.3s;
    overflow: initial;

    &::placeholder {
      color: $text-light;
    }

    &:hover {
      border-color: $accent-add;
    }

    &:focus {
      outline: none !important;
      border-color: $accent-add;
      background: $accent-light;

      & ~ .customTextarea-buttonContainer {
        background: $accent-light;
      }

      & ~ label {
        color: $text-dark;
      }
    }
  }
  .TextareaAutosizeRestrictionGrey {
    position: absolute;
    bottom: -25px;
    transform: translateY(-50%);
    right: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $text-light;
  }
  .TextareaAutosizeRestrictionRed {
    position: absolute;
    bottom: -25px;
    transform: translateY(-50%);
    right: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #fd2551;
  }
  // .emoji {
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   right: 50px;
  //   cursor: pointer;
  //   &:hover {
  //     & path {
  //       fill: #791186;
  //     }
  //   }
  // }
}
