@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.DropdawnCreateAccounts {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &__selected-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-right: 10px;
  }

  &__selected-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &__arrow {
    width: 24px;
    height: 24px;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px;
    position: absolute;
    right: 10px;
    top: 37px;
    max-height: 200px;
    z-index: 100;
    width: 250px;
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    overflow-y: scroll;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  &__row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  &__info>p {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    &:first-child {
      font-weight: 600;
    }

    &:last-child {
      opacity: 0.7;
    }
  }

  &__check {
    position: absolute;
    right: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 6px;
    height: 6px
  }

  &__delete {
    position: absolute;
    right: -8px;
    top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20px;
    height: 20px;
    z-index: 200;
  }
}