@import '../../../styles/utils/mixin';

.authContainerLogoWrapper {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  padding: 24px;
  width: 370px;
  border-radius: 12px;
  background-color: white;

  @media screen and (min-width: 1100px) {
    margin-left: 0;
    margin-right: 10px;
    align-items: flex-start;
    margin-left: 0px;
  }

  @media screen and (max-width: 1100px) {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

.authContainerLogo {
  margin-bottom: 0px;
}

.authNewlogoWizwork {
  width: 250px;
  margin-top: 80px;

  @media screen and (min-width: 1100px) {
    margin-top: 0;
    width: 300px;
  }

  @media screen and (max-width: 1100px) {
    margin-top: 0;
  }
}

.authlogoWizwork {
  width: 119px;
  height: 149px;

  @media screen and (min-width: 1100px) {
    width: 126px;
    height: 158px;
  }
}

.authContainerText {
  width: 350px;
  @include fontSemibold14;
  color: #363636;
  text-align: center;
  padding: 0 8px;

  @media screen and (min-width: 1100px) {
    padding: 0;
    text-align: start;
    font-size: 16px;
    width: 350px;
  }
}
