@import '../../../styles/utils/variables';

.select {

  &--errors,
  &--noErrors {
    .MuiOutlinedInput-root {
      padding-left: 34px !important;
    }
  }

  &--errors {
    .customInput>div {
      background-color: rgba(255, 246, 248, 1) !important;
    }
  }

  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $text-middle;
    margin-bottom: 8px;
  }


  &>.Mui-focused {
    & svg {
      color: $accent-main;
    }

    .customInput>div {
      background: $accent-light;
    }
  }

  .MuiAutocomplete-endAdornment {
    & svg {
      width: 18px;
      height: 18px;
    }
  }

  &>div:hover {
    & svg {
      transition: all .3s;
      color: $accent-main;
    }
  }

  .MuiAutocomplete-endAdornment {
    &>button:hover {
      background-color: transparent;

    }
  }
}

.MuiAutocomplete-noOptions {
  font-size: 14px;
  color: $text-middle;
}

.MuiAutocomplete-listbox[id='customSelect-listbox'] {
  max-height: 200px;

  & li {
    color: $text-middle;
    font-size: 14px;
  }

  & li[aria-selected="true"] {
    background-color: transparent;
    color: $accent-main;
    position: relative;

    &::after {
      content: '';
      width: 9px;
      height: 5px;
      transform: rotate(135deg);
      border-top: 2px solid green;
      border-right: 2px solid green;
      position: absolute;
      right: 18px;
      bottom: 16px;
    }

    &.Mui-focused {
      background-color: $accent-light;
    }
  }
}