@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/variables';

.MessagesFooter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;

  &__textarea {
    width: 100%;
    min-height: 100px;
    max-height: 100px;
    resize: none;
    background-color: $accent-add;
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 16px 20px;
    text-align: left;
    
    &--ar {
      text-align: right;
    }

    &::placeholder {
      color: $text-middle;
      @include fontSemibold14;
    }
  }

  &__inputArea-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  
    &--ar {
      flex-direction: row-reverse;
    }
  }

  &__buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  &__picker {
    position: absolute;
    top: -465px;
    left: 0;

    &--ar {
      top: -465px;
      left: inherit;
      right: 0;
    }
  }

  &__images {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    &--ar {
      flex-direction: row-reverse;
    }
  }

  &__selectImages {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }

  &__img {
    position: relative;
  }

  &__delete-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 3px;
    top: 3px;
    background-color: $white;
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }
}