@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.PrivacyPolicyPageWrapper {
  position: relative;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 21px;
  padding-top: 0px;
  @media screen and (min-width: 1100px) {
    width: 1040px;
    padding-top: 100px;
  }
}
.backArrow {
  position: absolute;
  top: 24px;
  & > svg {
    color: $text-middle;
  }
}
.PrivacyPolicyPageWrapperShadow {
  width: 330px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  border-radius: 12px;
  box-shadow: $shadow;
  background-color: $white;
  z-index: 1;
  @media screen and (min-width: 1100px) {
    width: 1040px;
  }
}
.PrivacyPolicyLogoWrapper {
}
.logoWizwork {
  margin-top: 60px;
  margin-bottom: 16px;
  margin-left: 90px;
  @media screen and (min-width: 1100px) {
    display: block;
    padding-top: 0px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}

.PrivacyPolicyTextWrapper {
  margin-bottom: 24px;
}

.PrivacyPolicyTitle {
  margin-top: 0px;
  @include fontSemibold22;
  color: $text-dark;
  @media screen and (min-width: 1100px) {
    margin-top: 24px;
  }
}
.PrivacyPolicyText {
  @include fontRegular16;
  color: $text-dark;
  margin-top: 24px;
}

.PrivacyPolicyBtn {
  margin-top: 24px;
  padding-bottom: 24px;
  border: 0;
  outline: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 16px;
  color: rgba(121, 17, 134, 1);
  background-color: $accent-light;
  border: 1px solid transparent;
  border-radius: 12px;
  cursor: pointer;
}

.PrivacyPolicyBtn:hover,
.PrivacyPolicyBtn:focus {
  border: 1px solid rgba(121, 17, 134, 1);
  background-color: $accent-light;
}
