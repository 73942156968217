@import '../../styles/utils/variables';
.mobile-search {
  top: -48px;
  left: 0;
  position: absolute;
  padding: 8px 24px;
  width: 100%;
  height: 48px;
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 10000;

  transition: transform 0.2s linear;

  &--visible {
    transform: translateY(48px);

    @media screen and (min-width: 1100px) {
      transform: translateY(0);
    }
  }

  &__back-icon {
    color: #6c757d;
    cursor: pointer;
  }

  &__filter-button {
    box-shadow: 0px 1px 4px rgba(91, 29, 114, 0.1);
    :hover {
      fill: $accent-main;
    }
  }
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  width: 32px;
  height: 32px;
}
