@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.accountSettings-page {
  width: 375px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  box-shadow: 0px 4px 15px #f2f2f2;
  // border-radius: 12px;
  box-sizing: border-box;

  @media screen and (min-width: 1100px) {
    width: 1116px;
    margin-top: 10px;
    background: transparent;

    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 4px;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
  }

  &__inner {
    display: flex;
    margin-top: 24px;
  }

  @media screen and (max-width: 1100px) {
    position: relative;
    left: 0;
    top: 0;
    padding: 24px 24px 66px;
    width: 100%;
    background: $white;
    max-width: 100vw;
    min-height: 100vh;

    &__container {
      position: fixed;
      display: block;
      top: 48px;
      width: 100vw;
      height: 100%;
      z-index: 998;
      background: $white;
      transition: all 0.3s;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__inner {
      margin-top: 0;
      width: 100%;
    }

    &__mobile-title,
    &__button-back {
      @include fontSemibold22;
      text-align: left;
    }

    &__mobile-title {
      padding: 12px 24px;
    }

    &__button-back {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;
      color: $text-dark;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-tap-highlight-color: transparent;

      &-text {
        color: $text-dark;
      }
    }

    &__back-icon {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: 16px;
      }
    }
  }
}