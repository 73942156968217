@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.ActivityLogPage {
  min-width: 785px;
  max-width: 785px;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (max-width: 1100px) {
    max-width: auto;
    min-width: auto;
  }

  &__section {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    color: $text-dark;
    border-radius: 8px;
    padding-bottom: 12px;

    &--notifications {
      padding: 8px 0 0;
      background-color: $white;
      height: calc(100vh - 100px);
    }
  }

  &__links {
    & .css-19kzrtu {
      padding: 8px 0 0;

      @media screen and (max-width: 1100px) {
        padding: 0;
        // margin-top: 12px;
      }
    }

    & .css-8atqhb {
      width: 100%;
      // background-color: #fff;
      padding: 8px;
      border-radius: 8px;
      margin-bottom: 4px;

      @media screen and (max-width: 1100px) {
        background-color: transparent;
      }
    }

    & .css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
      padding: 0 0 14px 0px;
      min-width: min-content;
      @include fontSemibold16;
      color: $text-middle;
      text-transform: capitalize;

      &:first-child {
        margin-right: 24px;
      }

      &:hover {
        color: $text-dark;
      }

      &:focus {
        color: $accent-main;
      }
    }

    & .MuiTabs-flexContainer {
      justify-content: center;
    }

    & .css-1mrn996 {
      text-transform: capitalize;
    }

    &
      > .MuiBox-root
      > .MuiTabs-root
      > .MuiTabs-scroller
      > .MuiTabs-flexContainer
      > .MuiButtonBase-root {
      margin-right: 24px;
      padding: 12px 2px;
      min-width: min-content;
      @include fontSemibold16;
      text-transform: capitalize;

      &:hover {
        color: $text-dark;
      }

      &:focus {
        color: $accent-main;
      }
    }

    & > .MuiBox-root > .MuiTabs-root > .MuiTabScrollButton-horizontal {
      display: inline-flex;

      @media screen and (min-width: 1100px) {
        display: none;
      }
    }

    & > [role='tabpanel'] > .MuiBox-root {
      padding: 6px 0 0;

      @media screen and (min-width: 1100px) {
        padding: 12px 0 0;
      }
    }

    &--profiles {
      & .css-8atqhb {
        background-color: none;
      }
    }

    &--activity-log {
      & .css-8atqhb {
        background-color: #fff;
      }
    }
  }

  &__without-activity {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    color: $text-middle;
    @include fontSemibold16;
  }
}
