@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.TippyComponent {
  @include fontSemibold12;
  
  &__content {
    display: flex;
    flex-direction: column;
    padding: 4px 10px;

    color: $white;
  }

  &__title {
    font-weight: 600;
    text-align: center;
    margin-bottom: 8px;
  }

  &__main {
    font-weight: 400 !important;
    color: $stroke;
  }

  & ul {
    list-style-type: disc;
    padding-left: 10px;
  }
}
