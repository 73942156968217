@import '../../../styles/utils/mixin';

.followingWrapper {
}

.followingMainTitle {
  margin-bottom: 12px;
  margin: 0 24px 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  @media screen and (min-width: 1100px) {
    margin-left: 0;
    font-size: 22px;
    line-height: 30px;
  }
}

.followingFrame {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
}

.following__wrapper {
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1100px) {
    max-width: 700px;
    flex-direction: row;
  }

  @media screen and (min-width: 1440px) {
    width: 753px;
  }
}

.following__img {
  margin-right: 10px;
  border-radius: 50%;
}

.following__titleWrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.following__title {
  max-width: 230px;
  @include fontSemibold18;
  color: #363636;
  cursor: pointer;

  @media screen and (min-width: 1100px) {
    max-width: 400px;
  }
}

.following__subtitle {
  max-width: 230px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #363636;

  @media screen and (min-width: 1100px) {
    max-width: none;
    font-size: 14px;
    line-height: 19px;
  }
}

.following__btnWrapper {
  width: 100%;

  @media screen and (min-width: 1100px) {
    width: auto;
  }
}

.following__btnWrapper .mainButton {
  padding: 8px 16px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;

  @media screen and (min-width: 1100px) {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 19px;
  }
}

.following__btnWrapper .secondaryButton {
  padding: 8px 16px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;

  @media screen and (min-width: 1100px) {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 19px;
  }
}
