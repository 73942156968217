.post {
  &__survey {
  }

  &__survey-container {
    margin-bottom: 12px;
    padding: 12px;
    background: #f8f8f8;
    border-radius: 16px;
  }

  &__survey-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }

  &__survey-subtitle {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 16px;
  }

  &__vote-chart-container {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
  }

  &__vote-container {
    padding: 0 16px;
    position: relative;
    margin-bottom: 25px;

    &::after {
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -12px;
      content: '';
      background-color: #e9e9e9;
      pointer-events: none;
    }

    &:last-child {
      margin-bottom: 0;

      &::after {
        content: none;
      }
    }
  }

  &__vote-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #363636;
  }

  &__vote-title span {
    display: inline-block;
    text-align: right;
    width: 37px;
    font-weight: 600;
    width: 36px;
  }

  &__vote-line-container {
    padding: 1px 16px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__vote-line-img {
    width: 24px;
    height: 24px;
  }

  &__vote-line {
    height: 4px;
    border-left: 4px solid #791186;
    border-right: 4px solid #791186;
    border-radius: 4px;
    background-color: #791186;
  }

  &__survey-radio-container {
    padding: 12px 12px 0;

    & .MuiFormControlLabel-root {
      position: relative;
      margin-right: 0;
      margin-bottom: 25px;
      border-radius: 24px;
      transition: 0.2s linear;
      font-family: inherit;

      &:hover {
        @media screen and (min-width: 1100px) {
          background-color: #791186;
        }
      }

      &:hover .MuiSvgIcon-root {
        @media screen and (min-width: 1100px) {
          color: #fff;
        }
      }

      &:hover .MuiTypography-root {
        @media screen and (min-width: 1100px) {
          color: #fff;
        }
      }

      &::after {
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -12px;
        content: '';
        background-color: #e9e9e9;
        pointer-events: none;
      }

      &:last-child {
        margin-bottom: 0;

        &::after {
          content: none;
        }
      }
    }
  }

  &__survey-votes-container {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  &__survey-votes {
    font-size: 13px;
    line-height: 18px;
    color: #000;
  }
}
