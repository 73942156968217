@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.BooksModal {
  padding: 8px 8px 8px 8px;

  @media screen and (min-width: 1100px) {
    padding: 8px 8px 16px 24px;
  }

  &__container {
    width: 300px;
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    // width: 1008px;
    // height: 380px;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
      width: 1008px;
      height: 380px;
    }
  }

  &__noBooks {
    @include fontRegular14;
    color: $text-middle;
    margin: 0 auto;
    padding-right: 8px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 40px;
  }
}

.Book {
  position: relative;

  &__deleteBtn {
    @include iconWithBackground;
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 2;
  }

  &__container {
    border-radius: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;
    gap: 10px;
    transition: $transition;
    cursor: pointer;

    &:hover {
      background-color: $white;
    }
  }

  &__cover {
    object-fit: fill;
    width: 210px;
    height: 292px;
    border-radius: 8px;
  }

  &__title {
    @include fontSemibold18;
    text-align: center;
    color: $accent-main;
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__writer {
    @include fontRegular14;
    text-align: center;
    color: $text-middle;
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
