/* @import '../../styles/utils/variables'; */

.loader_backDrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(58, 5, 65, 0.05);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4999;
}

.loader-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5000;
  width: 100%;
  height: 100%;
}

/* @property --loader_l0 {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: false;
}
@property --loader_l1 {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: false;
} */

.loader {
  /*Since it's gonna be circle, width and height are the same.*/
  --width: 64px;
  --helper: 0%;
  position: absolute;
  left: calc(50% - calc(var(--width) / 2));
  top: calc(50% - calc(var(--width) / 2));
  perspective: 1000px;
}

.loader-done {
  position: absolute;
  display: none;
  border-top: 3px solid #7051c3;
  border-left: 3px solid #7051c3;
  border-top-left-radius: 3px;
  width: calc(var(--width) / 6);
  height: calc(var(--width) / 3);
  top: calc(var(--width) / 2 - 15px);
  left: calc(var(--width) / 2 - 7px);
  transform: rotateZ(220deg);
  animation: loaderFadeIn 0.6s;
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: var(--width);
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}

$zero_perc1: percentage(0.00000001);
$zero_perc2: percentage(0.00000002);
$zero_perc3: percentage(0.0000003);

.circle::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 3px;
  border-radius: 100%;
  background: linear-gradient(45deg,
      rgba(169, 71, 244) $zero_perc1,
      rgba(242, 68, 121) $zero_perc2,
      rgba(169, 71, 244) $zero_perc3,
      rgba(242, 68, 121) 100%,
    );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.circle.c1 {
  animation: rx 2s infinite, gradientMove 1s infinite;
}

.circle.c2 {
  animation: ry 1s infinite, gradientMove 0.5s infinite;
}

.circle.c3 {
  animation: rz 1.5s infinite, gradientMove 0.75s infinite;
}

.loader.done .circle.c1,
.loader.done .circle.c2,
.loader.done .circle.c3 {
  animation: rfinal 0.5s;
}

.loader.done .loader-done {
  display: block;
}

@keyframes rx {
  from {}

  50% {
    opacity: 0.6;
  }

  100% {
    transform: rotateZ(360deg) rotateX(180deg);
  }
}

@keyframes ry {
  from {}

  60% {
    filter: blur(1px);
    opacity: 0.4;
  }

  100% {
    transform: rotateZ(180deg) rotateY(180deg);
  }
}

@keyframes rz {
  from {}

  40% {
    filter: blur(1px);
    opacity: 0.8;
  }

  100% {
    transform: rotateZ(180deg) rotateY(-180deg);
  }
}

@keyframes loaderFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes gradientMove {
  0% {
    --button_b: 0%;
    --button_b_out: 0%;
  }

  33% {
    --button_b: 100%;
    --button_b_out: 0%;
  }

  99% {
    --button_b: 100%;
    --button_b_out: 100%;
  }

  100% {
    --button_b: 0%;
    --button_b_out: 0%;
  }
}

@-webkit-keyframes rx {
  from {}

  50% {
    opacity: 0.6;
  }

  100% {
    transform: rotateZ(360deg) rotateX(180deg);
  }
}

@-webkit-keyframes ry {
  from {}

  60% {
    filter: blur(1px);
    opacity: 0.4;
  }

  100% {
    transform: rotateZ(180deg) rotateY(180deg);
  }
}

@-webkit-keyframes rz {
  from {}

  40% {
    filter: blur(1px);
    opacity: 0.8;
  }

  100% {
    transform: rotateZ(180deg) rotateY(-180deg);
  }
}

@-webkit-keyframes loaderFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes gradientMove {
  0% {
    --button_b: 0%;
    --button_b_out: 0%;
  }

  33% {
    --button_b: 100%;
    --button_b_out: 0%;
  }

  99% {
    --button_b: 100%;
    --button_b_out: 100%;
  }

  100% {
    --button_b: 0%;
    --button_b_out: 0%;
  }
}