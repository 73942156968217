@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.reactions-peoples {
  left: -100vw;
  top: 48px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
  z-index: 900;
  transition: 0.2s linear;
  padding: 12px;

  &--visible {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100vw);
    z-index: 1000;

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }

  &--in-modal {
    top: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 375px;
    padding: 16px 24px 110px;

    & > ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    & > ul > li {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    text-align: center;
    @include fontSemibold16;

    margin-bottom: 20px;

    & svg {
      margin-right: 12px;
      cursor: pointer;
    }
  }
}

.postCreation {
  display: flex;

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;

    >.customInput {
      margin-top: -27px;
      margin-bottom: 12px;
    }
  }

  &__divider {
    height: 1px;
    background-color: $stroke;

    margin-bottom: 16px;
  }

  &__inputItem {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 12px;
    gap: 4px;

    width: 100%;

    & svg {
      cursor: pointer;
      margin-bottom: 8px;
    }

    .MuiSvgIcon-root {
      color: $text-middle;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        color: $accent-hover;
        transform: rotate(180deg);
      }
    }
  }

  &__typeOfPost {
    display: flex;
    justify-content: space-between;
    column-gap: 12px;
    margin-bottom: 16px;

    display: grid;
    // grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;

    @media (max-width: 768px) {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;
    }
  }

  &__buttonType {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 2px;
    width: 100%;

    text-align: center;
    background-color: $tags;
    border-radius: 8px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: background-color 0.2s linear;
    @include fontSemibold13;

    &:hover {
      background-color: $accent-light;
      border: 2px solid $accent-hover;
      color: $text-dark;
    }

    &--selected {
      background-color: $accent-hover;
      color: $white;
    }

    &[data-disabled='true'] {
      pointer-events: none;
      color: #a7a7a7;
    }
  }

  &__buttonAdd {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    bottom: 6px;
    left: 16px;
    padding: 0;
    margin: 20px 0;

    @include fontSemibold14;
    color: $accent-main;
    cursor: pointer;
    border-radius: 4px;
    background-color: transparent;
    border: 2px solid transparent;

    &:hover {
      color: $accent-hover;

      & svg {
        color: $accent-hover;
      }
    }

    &[disabled] {
      color: $text-light;

      & svg {
        color: $text-light;
      }
    }

    & svg {
      color: $accent-main;
      width: 16px;
      height: 16px;
    }
  }

  &__helpText {
    @include fontRegular12;
    color: $text-light;

    padding-left: 37px;
    margin-bottom: 12px;
  }

  &__interestsInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    // @media screen and (max-width: 1100px) {
    //   margin-bottom: 60px;
    // }
  }

  &__interestsBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    padding: 6px 10px;
    box-sizing: border-box;

    @include fontSemibold13;
    background-color: $tags;
    border-radius: 8px;
    border: 2px solid transparent;
    cursor: pointer;

    &:hover {
      background-color: $accent-light;
      border: 2px solid $accent-main;
      color: $text-dark;
    }

    &--selected {
      color: $white;
      background-color: $accent-hover;
      border: 2px solid $accent-hover;
    }

    & ~ svg {
      cursor: pointer;
    }

    &-required {
      color: $text-dark;
    }
  }

  &__interestsBtn-required {
    color: $text-dark;
    color: red;
  }

  &__countSelectedChips {
    @include fontSemibold14;
    color: $text-middle;

    margin-top: 6px;
  }

  &__interestsList {
    margin-top: 10px;
  }

  &__btnSelectAll {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  &__chips {
    & div {
      margin-right: 8px;
      margin-bottom: 8px;
    }

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  &__imgsList {
    display: flex;
    flex-direction: row;
    gap: 14px;
    flex-shrink: 1;
    flex-wrap: wrap;
  }

  &__mediaItem {
    position: relative;
    display: flex;

    &:last-child {
      margin-bottom: 12px;
    }

    &--video {
      width: 100%;
    }

    .MuiSvgIcon-root {
      display: flex;
      position: absolute;
      top: 10px;
      right: 10px;

      width: 18px;
      height: 18px;
      color: $white;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        color: $accent-hover;
        transform: rotate(180deg);
      }
    }
  }

  &__mediaContainer {
    display: flex;
    height: 240px;
    width: 177px;
    overflow: hidden;
    border-radius: 8px;

    @media screen and (max-width: 1100px) {
      height: 205px;
      width: 156px;
    }

    & > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &--video {
      height: 250px;
      width: 100%;

      & > video {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      & ~ svg:last-child {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 60px;
        height: 60px;
        color: $white;
        cursor: pointer;

        &:hover {
          color: $accent-hover;
          transform: rotate(180deg);
        }
      }
    }
  }

  &__attachment {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 7px 12px;
    margin-bottom: 12px;

    width: 100%;
    background-color: $accent-light;
    @include fontSemibold14;
    color: $accent-main;
    border-radius: 8px;

    & > .MuiSvgIcon-root {
      display: flex;
      // position: absolute;
      top: 8px;

      width: 18px;
      height: 18px;
      color: $accent-hover;

      &:first-child {
        left: 12px;
        margin-right: 8px;
      }

      &:last-child {
        position: absolute;
        right: 12px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__btn-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 24px;

    @media screen and (min-width: 768px) {
      margin-bottom: 24px;
    }

    @media screen and (min-width: 1100px) {
      margin-bottom: 12px;
    }
  }
}
