@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.CreateBook {
  width: 300px;
  border-top: 1px solid $stroke;
  padding-top: 16px;
  @media screen and (min-width: 1100px) {
    width: 812px;
  }

  > .customInput {
    margin-bottom: 12px;
  }

  > .ModalFooter {
    border-top: 1px solid $stroke;
    margin-top: 0;
    padding-top: 16px;
  }

  &__cover {
    position: relative;
    margin-bottom: 12px;
    border-radius: 4px;
    width: 165px;
    height: 230px;
    overflow: hidden;

    &--empty {
      position: relative;
      margin-bottom: 12px;
      width: 100%;
      height: 230px;
      overflow: hidden;
    }

    &-remove.MuiSvgIcon-root {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 18px;
      height: 18px;
      cursor: pointer;
      color: $white;
      transition: $transition;

      &:hover {
        color: $accent-main;
        transform: rotate(180deg);
      }

      &:active {
        color: $accent-hover;
        transform: rotate(180deg);
      }
    }

    &-img {
      width: auto;
      height: auto;
    }
  }
}
