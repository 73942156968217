@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.wall {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (min-width: 1100px) {
    margin: 0 20px;
    min-width: 500px;
    max-width: 610px;
    margin-left: 282px !important;
  }

  @media screen and (min-width: 1440px) {
    margin: 0 20px;
    min-width: 785px;
    max-width: 785px;
    margin-left: 344px !important;
  }

  &__online-status {
    position: absolute;
    left: -20px;
    bottom: 0px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-bottom: 3px;

    @media screen and (max-width: 1100px) {
      width: 10px;
      height: 10px;
      position: relative;
      left: 0;
      top: none;
    }
  }

  &__header {
    margin-bottom: 12px;

    &-bg-container {
      margin-bottom: 16px;
      max-width: 785px;
      height: 186px;
      position: relative;
      overflow: hidden;

      @media screen and (min-width: 1100px) {
        border-radius: 8px;
      }
    }

    &-bg-btn-container {
      height: 24px;
      width: 24px;
      position: absolute;
      right: 20px;
      bottom: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgb(91 29 114 / 10%);
      overflow: hidden;

      @media screen and (min-width: 1100px) {
        height: 32px;
        width: 32px;
      }

      & svg {
        width: 80%;
      }
    }

    &-bg-btn-uploader {
      height: 24px;
      width: 24px;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
    }

    &-bg__btns {
      opacity: 0;
      display: flex;
      column-gap: 10px;
      position: absolute;
      z-index: 5;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s;
    }

    &-bg-container {
      height: 123px;
      position: relative;

      @media screen and (min-width: 1100px) {
        height: 186px;
      }

      &:hover .wall__header-bg__btns {
        opacity: 1;
      }
    }

    &-disabled-message {
      padding: 5px;
      position: absolute;
      left: 12px;
      right: 12px;
      top: 12px;
      font-weight: 600;
      font-size: 15px;
      color: #fd2551;
      background-color: #ffffff66;
      border: 1px solid #fd2551;
      border-radius: 4px;
      z-index: 998;

      @media screen and (min-width: 1100px) {
        padding: 16px;
        left: 24px;
        right: 24px;
        top: 24px;
        font-size: 22px;
      }
    }

    &-bg {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &-user-row {
      margin: 0 16px;
      padding-top: 20px;
      padding-bottom: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      color: #363636;

      @media screen and (min-width: 1100px) {
        flex-direction: row;
        padding-top: 0;
        padding-bottom: 53px;
        padding-left: 188px;
      }
    }

    &-avatar-container {
      position: absolute;
      overflow: hidden;
      border-radius: 50%;
      left: calc(50% - 60px);
      top: -110px;
      width: 120px;
      height: 120px;

      @media screen and (min-width: 1100px) {
        left: 0;
        top: -60px;
        width: 160px;
        height: 160px;
      }
    }

    &-avatar {
      object-fit: cover;
      position: absolute;
      bottom: 50%;
      left: 50%;
      width: inherit;
      height: inherit;
      min-width: inherit;
      min-height: inherit;
      transform: translate(-50%, 50%);
      border-radius: 50%;
    }

    &-avatar-btn {
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: calc(50% + 34px);
      top: -30px;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(91, 29, 114, 0.1);

      @media screen and (min-width: 1100px) {
        height: 32px;
        width: 32px;
        left: 120px;
        top: 60px;
      }

      & svg {
        width: 80%;
      }
    }

    &-avatar-btn-uploader {
      height: 24px;
      width: 24px;
      position: absolute;
      overflow: hidden;
      border-radius: 50%;
      opacity: 0;

      @media screen and (min-width: 1100px) {
        height: 32px;
        width: 32px;
      }
    }

    &-user-container {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &-user-name {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 8px;
      max-width: 278px;
      text-align: center;
      font-weight: 600;
      font-size: 22px;
      line-height: 25px;

      @media screen and (min-width: 1100px) {
        max-width: 320px;
        position: static;
        text-align: start;
      }
    }

    &-user-subtitle {
      max-width: 320px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 19px;

      @media screen and (min-width: 1100px) {
        text-align: start;
      }
    }

    &-user-followers {
      color: #791186;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }

    &-edit-btn-container {
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
      // display: flex;
      // flex-direction: column-reverse;
      gap: 10px;
      // right: 5px;
      top: -122px;

      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-end;

      & .secondaryButton {
        padding: 4px 8px;
        border-radius: 6px;
      }

      & .mainButton {
        padding: 5px 8px;
        border-radius: 6px;
      }

      @media screen and (min-width: 1400px) {
        position: static;
        margin-right: -16px;
        width: unset;
        gap: 24px;
      }

      @media screen and (min-width: 1100px) {
        position: static;
        margin-right: -16px;
        width: unset;
        gap: 10px;

        & .secondaryButton {
          padding: 11px 16px;
          border-radius: 12px;
        }

        & .mainButton {
          padding: 11px 16px;
          border-radius: 12px;
        }
      }
    }

    &-edit-btn {
      display: flex;
      // position: absolute;
      // right: 5px;
      // top: -125px;
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #791186;

      & .secondaryButton {
        padding: 4px 8px;
        border-radius: 6px;
      }

      @media screen and (min-width: 1100px) {
        position: static;

        & .secondaryButton {
          padding: 11px 16px;
          border-radius: 12px;
        }
      }
    }
  }

  &__links {
    &>.MuiBox-root>.MuiTabs-root>.MuiTabs-scroller {
      padding-left: 24px;

      @media screen and (min-width: 1100px) {
        padding-left: 0;
      }
    }

    &>.MuiBox-root>.MuiTabs-root>.MuiTabs-scroller>.MuiTabs-flexContainer>.MuiButtonBase-root {
      margin-right: 24px;
      padding: 12px 2px;
      min-width: min-content;
      @include fontSemibold16;
      text-transform: capitalize;

      &:hover {
        color: $text-dark;
      }

      &:focus {
        color: $accent-main;
      }
    }

    &>.MuiBox-root>.MuiTabs-root>.MuiTabScrollButton-horizontal {
      width: 30px;
      display: inline-flex;
      transition: 0.2s linear;

      @media screen and (min-width: 1100px) {
        display: none;
      }
    }

    &>.MuiBox-root>.MuiTabs-root>.Mui-disabled {
      width: 0;
      overflow: hidden;
      transition: 0.2s linear;
    }

    &>[role='tabpanel']>.MuiBox-root {
      padding: 6px 0 0;

      @media screen and (min-width: 1100px) {
        padding: 12px 0 0;
      }
    }
  }
}

.block-btn {
  position: absolute;
  left: 0;
  top: 60px;

  @media screen and (min-width: 1100px) {
    position: unset;
  }
}

.report-btn {
  position: absolute;
  right: 0px;
  top: 35px;

  @media screen and (min-width: 1100px) {
    position: unset;
  }
  
  @media screen and (max-width: 460px) {
    max-width: 95px;
  }

}

.follow-btn {
  position: absolute;
  right: 0px;
  top: 0px;

  @media screen and (min-width: 1100px) {
    position: unset;
  }
}

.unblock-btn {
  position: absolute;
  right: 0px;
  top: 60px;

  &.search{
    position: unset;
    width: 100%;
    & button{
      width: 100%;
    }

  }

  @media screen and (min-width: 1100px) {
    position: unset !important;
    top: -6px;

    &.search{
      width: unset;
      & button{
        width: unset;
      }
  
    }
  }
}

.message-btn {
  padding: 12px 16px;
  color: $accent-main;
  height: 43px;
  border: 1px solid $accent-main;
  box-sizing: border-box;
  border-radius: 12px;
  transition: all 0.3s;
  @include fontSemibold14;

  &:hover {
    background-color: $accent-add;
  }

  @media screen and (max-width: 1100px) {
    position: absolute;
    right: 0px;
    top: 120px;
    font-family: inherit;
    background: $accent-add;
    border: 1px solid $accent-add;
    transition: all 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 6px;
    height: fit-content;
  }
}