@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.signUp-mail {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin: 0 auto 24px auto;
  }

  &__title {
    @include fontSemibold22;
    text-align: center;
    color: black;
    margin: 0 auto 24px auto;
  }

  &__subtitle {
    @include fontRegular18;
    color: $text-dark;
    text-align: center;
    margin-bottom: 16px;

    >span {
      color: $accent-main;
    }
  }

  &__description {
    @include fontRegular18;
    color: $text-dark;
    text-align: center;
    margin-bottom: 24px;
  }

  .mainButton {
    width: 100px;
  }

  @media screen and (max-width: 1100px) {
    img {
      margin-bottom: 16px;
    }

    &__title {
      @include fontSemibold20;
      margin-bottom: 16px;
    }

    &__subtitle {
      @include fontSemibold16;
    }

    &__description {
      @include fontRegular14;
      margin-bottom: 0;
    }

    .mainButton {
      width: 279px;
    }
  }
}
