@import '../../../styles/utils/mixin';

:root {
  --toastify-color-progress-info: #791186 !important;
  --toastify-color-progress-success: #28a745;
  --toastify-color-progress-error: #fd2551;
}
.Toastify__toast-container {
  width: 335px;
  margin: 20px;
}
.Toastify__toast-container--top-right {
  top: 0px !important;
  right: 0px !important;
}
.Toastify__toast-icon {
  display: none;
}
.Toastify__toast > button > svg {
  display: none;
}

.Toastify__toast-body {
  padding-right: 20px;
}

.Toastify__toast::after {
  content: url('../Notify/close-notify.svg');
  position: absolute;
  color: #6c757d;
  font-size: 11px;
  font-weight: 700;
  right: 10px;
  top: 8px;
}

// ===================
.Toastify__toast--success {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(91, 91, 91, 0.15);
  border-radius: 12px;
  @include fontSemibold14;
  fill: #28a745;
  color: #28a745;
}
.Toastify__toast--success::before {
  content: url('../Notify/success.svg');
  position: relative;
  z-index: 100000;
  margin-right: 20px;
  left: 16px;
  top: 15px;
}

// ===================
.Toastify__toast--error {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(91, 91, 91, 0.15);
  border-radius: 12px;
  @include fontSemibold14;
  fill: #fd2551;
  color: #fd2551;
}
.Toastify__toast--error::before {
  content: url('../Notify/error.svg');
  position: relative;
  z-index: 100000;
  margin-right: 20px;
  left: 16px;
  top: 15px;
}

// ===================
.Toastify__toast--info {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(91, 91, 91, 0.15);
  border-radius: 12px;
  @include fontSemibold14;
  fill: #791186;
  color: #791186;
}
.Toastify__toast--info::before {
  content: url('../Notify/info.svg');
  position: relative;
  z-index: 100000;
  margin-right: 20px;
  left: 16px;
  top: 15px;
}
