@import '../../../styles/utils/mixin';
@import '../../../styles/utils/variables';

.ChatList {
  position: relative;
  width: 315px;
  border-right: 1px solid $stroke;
  z-index: 100;    
  display: flex;
  flex-direction: column;

  @media (max-width: 750px) {
    width: 100%;
    height: 100%;
    background-color: $accent-light;
  }

  &__header {
    height: 48px;
    padding: 13px 12px;
    box-sizing: border-box;
    background-color: $accent-add;
    border-radius: 8px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    @include fontSemibold16;
    font-weight: 700;
  }

  &__main {
    display: flex;
    flex-direction: column;
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: 750px) {
      max-height: 100%;
      flex: 1;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 8px 12px;
    background-color: $accent-light;
    border-top: 1px solid $stroke;
    cursor: pointer;

    &--ar {
      flex-direction: row-reverse;
    }

    &--unread {
      background-color: $accent-medium;
    }
  }

  &__photo-wrapper {
    position: relative;
  }

  &__photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    
    margin-right: 12px;

    &--ar {
      margin-left: 12px;
      margin-right: 0;
    }
  }

  &__online-status {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: $white;
    right: 6px;
    bottom: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--ar {
      right: inherit;
      left: 6px;
    }
  }

  &__online-status-color {
    width: 12px;
    height: 12px;
    background-color: $red;
    border-radius: 50%;
  }

  &__nameContainer {
    display: flex;
    flex-direction: column;
    gap: 3px;
    min-width: 175px;
    max-width: 175px;
    overflow: hidden;
  }

  &__name {
    @include fontSemibold14;
    font-weight: 700;
    color: $text-dark;
  }

  &__msg {
    @include fontRegular13;
    color: $text-middle;
  }

  &__icon {
    margin-top: 6px;
  }

  &__date {
    @include fontRegular14;
    color: $text-dark;
    position: absolute;
    right: 12px;
    height: 8px;

    &--ar {
      right: inherit;
      left: 12px;
    }
  }

  &__chat-search {
    height: 32px;
    width: 32px;
    overflow: hidden;
    border-radius: 40px;
    background: #FBF5FF;
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 6px;
    gap: 6px;
    box-sizing: border-box;

    &--active {
      padding: 0 16px;
      width: 176px;
    }

    &>input {
      width: 100%;
      background-color: transparent;
      outline: none;
      border: none;
    }

    &--ar {
      flex-direction: row-reverse;

      &>input {
        text-align: right;
      }
    }
  }
}