@import '../../../../styles/utils/variables';

.uploadBtn {
  box-sizing: border-box;
  width: 160px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  background: $accent-light;
  color: $accent-main;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  @media screen and (min-width: 1100px) {
    background: $accent-main;
    color: $white;
  }

  &:focus,
  &:hover {
    background-color: $accent-light;
    @media screen and (min-width: 1100px) {
      background-color: $accent-hover;
    }
  }

  &[disabled] {
    background: $stroke;
    pointer-events: none;
  }

  &--paddingNone {
    padding: 0;
  }

  &--transparent {
    background: transparent;
    color: $accent-main;

    &:focus,
    &:hover {
      background: transparent;
      color: $accent-hover;
    }

    &[disabled] {
      background: transparent;
      color: $text-light;
      pointer-events: none;
    }
  }
}
