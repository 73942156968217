@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.ModalFooter {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 16px;

  &--mobile {
    position: absolute;
    bottom: 24px;
    width: 88%;

    @media (max-width: 452px) {
      bottom: 11px;
    }
  }
}
