.post {
  &__header-more-btn-container {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 1px 4px rgba(91, 29, 114, 0.1);
    cursor: pointer;
    transition: background-color 0.2s linear;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: #FBF5FF;
    }
  }

  &__header-more-btn {
    fill: #6C757D;
    transition: fill 0.2s linear;

    &:hover {
      fill: #620C6D;
    }
  }
}