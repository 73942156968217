@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.header {
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  height: 48px;
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (min-width: 1100px) {
    width: 100%;
    max-width: 1440px;
    padding-right: 18px;
    padding-left: 40px;
    height: 60px;
  }

  &-wrapper {
    // width: calc(375px - 8px);
    width: 100vw;
    background: $white;
    box-shadow: 0px 1px 12px rgba(121, 107, 121, 0.1);
    position: fixed;
    top: 0;
    z-index: 999;

    @media screen and (min-width: 1100px) {
      width: calc(100vw - 12px);
    }
  }

  &-box {
    display: flex;
    align-items: center;
  }

  &-burger-button {
    margin-right: 12px !important;
    position: relative;
    border-radius: 50%;
    box-shadow: 0px 1px 4px rgba(91, 29, 114, 0.1);

    @media screen and (min-width: 1100px) {
      display: none !important;
    }
  }

  &-burger-button-img {
    height: 24px;
    width: 24px;
    opacity: 1;
    transition: 0.2s linear;

    &--hide {
      transform: rotateZ(360deg);
      opacity: 0;
    }
  }

  &-burger-button-img-close {
    position: absolute;
    height: 24px;
    width: 24px;
    opacity: 0;
    transition: 0.2s linear;

    &--show {
      transform: rotateZ(360deg);
      opacity: 1;
    }
  }

  &-logo {
    display: none;
    width: 117px;
    height: 38px;
    margin-left: 10px;
    align-items: center;
    cursor: pointer;

    @media screen and (min-width: 1100px) {
      display: flex;
      margin-right: 12px;
      margin-left: 12px;
      // margin-right: 24px;
      width: 155px;
    }

    & > img:first-child {
      margin-right: 7px;
      width: 32px;
      display: none;

      @media screen and (min-width: 1100px) {
        display: block;
      }
    }

    & > img:last-child {
      width: 102px;

      @media screen and (min-width: 1100px) {
        width: 100%;
      }
    }
  }

  &-logo-text-for-mobile {
    display: block;
    margin-right: 8px;
    cursor: pointer;

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }

  &-search {
    width: 176px;
  }

  &-navigation {
    height: 100%;
    display: none;
    align-items: center;
    position: absolute;
    left: 55%;
    transform: translate(-50%);

    @media screen and (min-width: 1100px) and (max-width: 1280px) {
      left: 58%;
    }

    @media screen and (min-width: 1100px) {
      display: flex;
    }

    &__admin {
      @media screen and (min-width: 1440px) {
        left: 55%;
      }

      @media screen and (min-width: 1390px) and (max-width: 1440px) {
        left: 60%;
      }

      @media screen and (min-width: 1100px) and (max-width: 1390px) {
        left: 63%;
      }
    }

    & li {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 35px;
      color: $text-middle;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      border-bottom: 2px solid transparent;

      @media screen and (min-width: 1100px) {
      }

      &.header-navigation__item--checked {
        background-color: #fbf5ff;

        @media screen and (min-width: 1100px) {
          border-color: $accent-main;
          background-color: transparent;
        }

        & a {
          color: $accent-main !important;
        }

        & svg path {
          fill: $accent-main !important;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      & svg {
        margin-right: 8px;
      }

      & a {
        display: flex;
        align-items: center;
        color: inherit;
        cursor: pointer;

        &:hover {
          color: $text-dark;

          & path {
            fill: $text-dark;
          }
        }

        & > img {
          margin-right: 8px;
        }
      }
    }

    &__mail-icon {
      position: relative;
    }

    &__msg-counter-wrapper {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      left: -6px;
      top: -5px;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__msg-counter {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $red;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: $white;
      @include fontSemibold12;
    }
  }

  &-input-container {
    display: none;

    @media screen and (min-width: 1100px) {
      display: block;
    }
  }

  &-search-icon {
    display: flex;
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }

  &-search-icons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;
    @media screen and (min-width: 1100px) {
      gap: 12px;
    }
  }

  &-profile {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.3s;
    min-width: 32px;

    @media screen and (min-width: 1100px) {
      padding-right: 22px;
    }

    & > img {
      object-fit: cover;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      @media screen and (min-width: 1100px) {
        margin-right: 8px;
      }
    }

    &:hover {
      color: $accent-main;
    }
  }

  &-arrow-icon {
    display: none !important;

    @media screen and (min-width: 1100px) {
      display: flex !important;
    }
  }

  &-popover {
    width: 260px;
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 8px;

    &__angle {
      position: absolute;
      right: 24px;
      top: -12px;
    }

    &__header {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $text-dark;
      padding: 4px 12px;

      & img {
        object-fit: cover;
        width: 48px;
        height: 48px;
        margin-right: 12px;
        border-radius: 50%;
      }

      & button {
        position: absolute;
        right: 15px;
        top: 55%;
        transform: translateY(-50%);
      }
    }

    &__border {
      height: 1px;
      width: 100%;
      background: $stroke;
      margin: 4px 0;
    }

    &__icon {
      background: $accent-light;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 8px;
    }

    &__row {
      color: $accent-main;
      padding: 0px 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 48px;
      transition: all 0.3s;

      & a {
        color: inherit;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      &:hover {
        background: $accent-light;
        color: $accent-main;
      }

      & svg {
        color: $accent-main;
      }

      &--exit {
        color: $red;

        & svg {
          width: 20px;
          height: 20px;
          color: $red;
        }
      }

      &--exit:hover {
        color: $red;
        background: #fff5f5;
      }
    }

    &__mail-icon {
      position: relative;
    }
  }
}

#profile-popover {
  .MuiPaper-root {
    overflow: unset !important;
    box-shadow: 0px 1px 5px rgba(121, 17, 134, 0.06) !important;
    border-radius: 8px !important;
  }
}

.relax-room-mobile-btn {
  display: block;

  @media screen and (min-width: 1100px) {
    display: none;
  }
}
