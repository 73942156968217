@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.secondaryButton {
  @include fontSemibold14;
  background: $accent-light;
  border-radius: 12px;
  padding: 11px 16px;
  color: $accent-main;
  border: 1px solid $accent-light;
  transition: all .3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;

  &--bigPadding {
    padding: 11px 48px;
  }

  &--smallPadding {
    padding: 7px 11px;
  }

  &:focus,
  &:hover {
    border-color: $accent-main;
  }

  &[disabled] {
    color: $accent-add;
    background: $accent-light;
    pointer-events: none;
    cursor: not-allowed;
  }

  >svg {
    color: inherit;
  }
}