@import '../../../styles/utils/variables';

.ModalContainer {
  box-sizing: border-box;
  position: relative;
  padding: 24px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(91, 29, 114, 0.1);
  border-radius: 16px;
  z-index: 1000;

  &--congrats {
    box-shadow: 0 4px 15px 0 #f2f2f2;

    @media screen and (min-width: 1100px) {
      padding: 32px;
    }
  }

  &--mobile {
    min-height: 100%;
  }
}
