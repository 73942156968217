@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.ErrorIndicator {
  width: 100%;
  max-width: 310px;
  height: fit-content;
  margin: 16px auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &__title {
    @include fontSemibold22;
    text-align: center;
    color: $text-dark;
  }

  &__text {
    @include fontRegular16;
    text-align: center;
    color: $text-middle;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}
