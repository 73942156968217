@import '../../../styles/utils/mixin';

.followersWrapper {
}

.followersMainTitle {
  margin-bottom: 12px;
  margin: 0 24px 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  @media screen and (min-width: 1100px) {
    margin-left: 0;
    font-size: 22px;
    line-height: 30px;
  }
}

.followersFrame {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
}

.follower__wrapper {
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1100px) {
    width: 580px;
    flex-direction: row;
  }

  @media screen and (min-width: 1440px) {
    width: 753px;
  }
}

.follower__img {
  margin-right: 10px;
  border-radius: 50%;
}

.follower__titleWrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.follower__title {
  max-width: 230px;
  @include fontSemibold18;
  color: #363636;
  cursor: pointer;

  @media screen and (min-width: 1100px) {
    max-width: 400px;
  }
}

.follower__subtitle {
  max-width: 230px;
  @include fontRegular14;
  color: #363636;

  @media screen and (min-width: 1100px) {
    max-width: 230px;
    font-size: 14px;
    line-height: 19px;
  }

  @media screen and (min-width: 1440px) {
    max-width: none;
  }
}

.follower__btnWrapper {
  width: 100%;

  @media screen and (min-width: 1100px) {
    width: auto;
  }
}

.follower__btnCheckWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
}

.follower__btnCheckWrapper .secondaryButton {
  padding: 8px 16px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;

  @media screen and (min-width: 1100px) {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 19px;
  }
}

.follower__btnWrapper .mainButton {
  padding: 8px 16px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;

  @media screen and (min-width: 1100px) {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 19px;
  }
}

.follower__btnCheck {
  padding: 0;
  position: absolute;
  top: 4px;
  right: 10px;
  background: transparent;
  border: none;
  width: 78px;
  height: 19px;
  @include fontRegular12;
  color: #28a745;
  cursor: default;

  @media screen and (min-width: 1100px) {
    margin-right: 16px;
    position: relative;
    top: auto;
    right: auto;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  &::before {
    content: '';
    display: none;
    background: url('../../../images/avatars/followers-check.svg') no-repeat;
    width: 17px;
    height: 13px;
    position: absolute;
    top: 2px;
    left: -30px;
    z-index: 200;

    @media screen and (min-width: 1100px) {
      display: block;
    }
  }

  &::after {
    content: '';
    display: block;
    background: url('../../../images/circle-check-fill.svg') no-repeat;
    width: 16px;
    height: 16px;
    // float: left;
    // margin: 0 6px 0 0;
    position: absolute;
    top: 2px;
    right: -6px;
    z-index: 200;

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }
}

.followers__content {
  display: flex;
}

.followers__following-avatar-container {
  margin-right: 12px;
  min-width: 48px;
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.followers__following-avatar {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: inherit;
  height: inherit;
  min-width: inherit;
  min-height: inherit;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  object-fit: cover;
}
