@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.my-feed {
  display: flex;
  flex-direction: column;

  &__section {
    margin-bottom: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #363636;
    border-radius: 8px;
  }

  &__dropdown {
    height: 36px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #791186;
    padding: 8px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
  }

  &__ads {
    padding: 0px 24px 16px;

    &-more {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
    }
  }

  &__section-title {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;

    @media screen and (min-width: 1100px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__posts-title {
    margin: 0 24px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;

    @media screen and (min-width: 1100px) {
      margin-left: 0;
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__no-posts {
    display: flex;
    justify-content: center;
    margin: 12px 0 20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #6c757d;
    padding: 0 24px;
  }
}

.create-block {
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 12px;

    @media screen and (min-width: 1100px) {
      justify-content: flex-start;
    }
  }

  &__admin-row {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 179px;
    height: 34px;
    width: 159.5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    background-color: #f5f5f5;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s linear;

    &-text {
      color: #363636;
    }

    &-container {
      position: relative;
    }

    &-archive {
      @include iconWithBackground;
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 5;
      color: $text-middle;
    }

    @media screen and (min-width: 1100px) {
      width: 179px;
    }

    &:hover {
      background-color: #f3e1ff;
    }

    &[data-disabled='true'] {
      pointer-events: none;
      span {
        color: #a7a7a7;
      }
    }
  }
}

.create-post-block {
  &__row {
    display: flex;
    flex-direction: column;
    column-gap: 12px;
  }

  &__avatar-container {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    width: 32px;
    height: 32px;

    @media screen and (min-width: 1100px) {
      width: 48px;
      height: 48px;
    }
  }

  &__avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    width: inherit;
    height: inherit;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    object-fit: cover;
  }

  &__details {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;
    flex-grow: 1;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
  }

  &__pencil {
    position: absolute;
    top: 11.5px;

    @media screen and (min-width: 1100px) {
      top: 16.5px;
    }
  }

  &__placeholder {
    position: absolute;
    top: 9.5px;
    color: $text-middle;

    &:hover {
      cursor: pointer;
    }

    @media screen and (min-width: 1100px) {
      top: 14.5px;
    }
  }

  &__input {
    display: block;
    width: 100%;
    padding: 6px 0 6px 42px;
    height: 40px;
    background: #f8f8f8;
    border-radius: 50px 0 0 50px;
    border: none;
    color: #6c757d;

    &:focus {
      outline: 1px solid #fadcff;
    }

    &:hover {
      cursor: pointer;
      background: $accent-light;
    }

    @media screen and (min-width: 1100px) {
      height: 48px;
      border-radius: 50px;
    }
  }

  &__more {
    padding-left: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #6c757d;

    @media screen and (min-width: 1100px) {
      padding-left: 64px;
    }
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    column-gap: 24px;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__item {
    padding: 5px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: #fbf5ff;
    }
  }

  &__post-button-mobile .mainButton {
    padding: 0 12px;
    height: 40px;

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }

  &__post-button-desctop {
    display: none;

    @media screen and (min-width: 1100px) {
      display: block;
    }
  }
}
