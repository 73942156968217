@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.newPasswordWrapper {
  display: flex;
  justify-content: center;
  width: 300px;
  margin-top: 24px;
  padding-bottom: 16px;
  // border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid $stroke;
  margin-bottom: 16px;
  @media screen and (min-width: 1100px) {
    width: 400px;
  }

  .newPassword {
    @include fontSemibold22;
    color: $text-dark;
  }
}
.newPasswordText {
  // margin-left: auto;
  // margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: rgba(108, 117, 125, 1);
  @media screen and (min-width: 1100px) {
    font-size: 16px;
  }
}

// .newPasswordLink {
//   margin-bottom: 16px;
//   text-align: end;
//   font-family: 'Open Sans';
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 19px;
//   color: $accent-main;
// }
