@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.Chat {
  &__main {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    min-height: 100%;
    position: relative;
  }

  &__chatList-wrapper {
    position: relative;
    z-index: 1001;

    @media (max-width: 750px) {
      position: absolute;
      left: 0;
      right: 0;
      transform: translateX(-100%);
      transition: all 0.3s;
      height: 100%;

      &--open {
        transform: translateX(0);
      }
    }
  }

  &__empty {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__empty-title {
    @include fontSemibold32;
    color: $text-dark;
  }
}