@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.createAccountWrapper {
  display: flex;
  justify-content: center;
  width: 300px;
  margin-top: 24px;
  padding-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #e9e9e9;
  @media screen and (min-width: 1100px) {
    width: 400px;
  }
  .createAccount {
    @include fontSemibold22;
    color: #363636;
  }
  .wizwork {
    @include fontSemibold22;
    color: #791186;
    margin-left: 8px;
  }
}

.forgotPasswordLink {
  margin-top: 12px;
  margin-bottom: 16px;
  text-align: end;
  @include fontSemibold14;
  color: $accent-main;
}
