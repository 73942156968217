@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.InviteModal {
  width: 100%;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  background: $white;
  box-shadow: 0px 4px 15px 0px $shadow;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: 1100px) {
    padding: 24px;
    gap: 16px;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &__invite-img {
    width: 86px;
    height: 86px;

    @media screen and (max-width: 1100px) {
      width: 46px;
      height: 46px;
    }
  }

  &__oops-img {
    width: 80px;
    height: 80px;

    @media screen and (max-width: 1100px) {
      width: 40px;
      height: 40px;
    }
  }

  &__email-img {
    width: 71px;
    height: 71px;

    @media screen and (max-width: 1100px) {
      width: 36px;
      height: 36px;
    }
  }

  &__oops {
    @include fontSemibold32;
    color: $red;

    @media screen and (max-width: 1100px) {
      @include fontSemibold20;
    }
  }

  &__congrats {
    @include fontSemibold32;
    background: linear-gradient(90deg, #833AB4 0%, #FD1D1D 50%, #FCB045 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width: 1100px) {
      @include fontSemibold20;
    }
  }

  &__main-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;

    &>h2 {
      color: $text-dark;
      @include fontSemibold22;

      @media screen and (max-width: 1100px) {
        @include fontSemibold16;
      }

      &>span {
        color: $accent-main;
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    gap: 8px;
    color: $text-dark;
    @include fontRegular14;
  }

  &__input {
    outline: none;
    display: flex;
    width: 100%;
    height: 40px;
    padding: 6px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid $stroke;
    background: transparent;
    color: $accent-main;
    transition: all 0.3s;

    &::placeholder {
      color: $text-middle;
    }

    &:hover {
      border-color: $accent-add;
    }

    &:focus {
      border-color: $accent-add;
      background: $accent-light;
    }

    &--error {
      background-color: #FFF6F8;
      padding: 6px 16px 6px 40px;
    }

    &--ar {
      text-align: right !important;
    }

    &--ar-error {
      text-align: right !important;
      padding: 6px 40px 6px 16px;
    }
  }

  &__error {
    margin-top: 0;

    &::before {
      content: '';
      display: block;
      background: url('../../images/cross-icon.svg') no-repeat;
      width: 25px;
      height: 25px;
      float: left;
      position: absolute;
      top: 42px;
      left: 20px;
      z-index: 200;
    }
  }

  &__error-ar {
    margin-top: 0;

    &::before {
      content: '';
      display: block;
      background: url('../../images/cross-icon.svg') no-repeat;
      width: 25px;
      height: 25px;
      float: left;
      position: absolute;
      top: 42px;
      right: 10px;
      z-index: 200;
    }
  }
}