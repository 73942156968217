@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.ReportReasons {
  &__list {
    max-height: 400px;
    overflow: auto;
  }

  &__wrapper {
    padding: 8px 0;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    border-bottom: 1px solid #e9e9e9;

    &:first-child {
      border-top: 1px solid #e9e9e9;
    }

    @media screen and (min-width: 1100px) {
      width: 100%;
      flex-direction: column;
    }
  }

  &__container-user {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__container-avatar {
    position: relative;
    margin-right: 12px;
    min-width: 40px;
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }

  &__avatar {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: inherit;
    height: inherit;
    min-width: inherit;
    min-height: inherit;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    object-fit: cover;
  }

  &__title-wrapper {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__title {
    // max-width: 230px;
    @include fontSemibold18;
    color: #363636;
    cursor: pointer;

    @media screen and (min-width: 1100px) {
      max-width: 400px;
    }
  }

  &__created {
    // max-width: 230px;
    @include fontRegular14;
    color: $text-light;

    @media screen and (min-width: 1100px) {
      max-width: none;
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__reason-text {
    @include fontRegular16;
    color: $text-dark;
  }
}
