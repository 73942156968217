@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/variables';

.Message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 24px 0 24px;
  gap: 12px;

  &--ar {
    flex-direction: row-reverse;
  }

  &__avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 500px;
    padding-top: 10px;

    &--ar {
      align-items: flex-end;
    }

    @media screen and (max-width: 1100px) {
      width: 400px;
    }

    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }

  &__name {
    @include fontSemibold14;
    color: $text-dark;
  }

  &__text {
    height: max-content;
    width: 100%;
    white-space: pre-line;
    overflow-wrap: break-word;

    &>p {
      display: block;
      white-space: pre-line;
      overflow-wrap: break-word;
      width: 100%;
    }
  }

  &__images {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  &__image {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
}