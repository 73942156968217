@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.post {
  &__petition-email-container {
    @include fontRegular14;
    color: $text-dark;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 6px;
  }

  &__petition-email-title {
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-top: 4px;
    }
  }

  &__petition-email {
    @include fontSemibold14;
    color: $accent-main;
    display: none;
    padding-left: 2px;

    @media screen and (min-width: 1100px) {
      display: block;
      padding-left: 0;
    }
  }

  &__petition-email-mob {
    display: inline;

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }

  &__petition-email-visibility {
    display: flex;
    align-items: center;
    margin-left: -4px;

    > svg {
      width: 16px;
      height: 16px;
      color: $accent-main;
      transition: $transition;
    }

    &:hover {
      > svg {
        color: $accent-hover;
      }
    }
  }

  &__petition-copy-container {
    @include fontSemibold13;
    color: $text-middle;
    box-shadow: 0px 0.75px 3px 0 rgba(91, 29, 114, 0.1);
    border-radius: 58.5px;
    padding: 3px 8px;
    display: flex;
    align-items: center;
    gap: 7px;
    transition: $transition;

    > svg {
      width: 18px;
      height: 18px;
      transition: $transition;
    }

    &:hover {
      background-color: $accent-light;

      > svg,
      > span {
        color: $accent-hover;
      }
    }

    &:active {
      background-color: $white;

      > svg,
      > span {
        color: $accent-main;
      }
    }
  }
}
