@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.customInput {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  &-inner {
    position: relative;
    border-radius: 8px;

    &--focused {
      background: $accent-light;
    }
  }

  // === ERROR ICONS ===
  &--errors {
    & input {
      padding-left: 40px;
    }

    .customInput-inner {
      background-color: rgba(255, 246, 248, 1) !important;
    }

    & input {
      background-color: rgba(255, 246, 248, 1) !important;

      &::placeholder {
        color: $text-light !important;
      }
    }

    & fieldset {
      border-color: #FFD7DF !important;
    }

    .MuiOutlinedInput-root:hover {
      & input {
        &::placeholder {
          opacity: 1 !important;
          color: #6c757d !important;
        }
      }
    }

    .MuiInputAdornment-root {
      & svg {
        color: #363636 !important;
      }
    }

    &::before {
      content: '';
      display: block;
      background: url('../../../images/cross-icon.svg') no-repeat;
      width: 25px;
      height: 25px;
      float: left;
      // margin: 0 6px 0 0;
      position: absolute;
      top: 42px;
      left: 18px;
      z-index: 200;
    }
  }

  &--noErrors {
    & input {
      padding-left: 40px;
    }

    &::before {
      content: '';
      display: block;
      background: url('../../../images/check-icon.svg') no-repeat;
      width: 25px;
      height: 25px;
      float: left;
      // margin: 0 6px 0 0;
      position: absolute;
      top: 42px;
      left: 18px;
      z-index: 200;
    }
  }

  // === === === ===

  &--disabled {
    pointer-events: none;

    .customInput-label {
      color: $text-light;
    }

    & input {
      &::placeholder {
        opacity: 1;
      }
    }

    & fieldset {
      border-color: $bg !important;
    }
  }

  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $text-middle;
    margin-bottom: 8px;
  }

  &-label--focused {
    color: $text-dark;
  }

  .MuiInputAdornment-root {
    & svg {
      width: 16px;
      height: 16px;
    }
  }

  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }

  .MuiInputBase-inputAdornedEnd {
    padding-right: 14px;
  }

  .MuiInputAdornment-positionEnd {
    padding-right: 8px;
  }

  &-adornment--checked {
    .MuiInputAdornment-root {
      & svg {
        color: #791186;
      }
    }
  }

  & input {
    height: 23px;
    border-radius: 8px;
    color: $accent-main;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: inherit;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px $white inset;
      -webkit-text-fill-color: $accent-main;
    }

    &::placeholder {
      opacity: 1;
      color: $text-light;
    }
  }

  & fieldset {
    border: 1px solid $stroke;
  }

  .MuiOutlinedInput-root:hover {
    & input {
      &::placeholder {
        opacity: 1;
        color: $text-middle;
      }
    }

    & fieldset {
      border-color: $accent-add;
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    & input:focus {
      background: $accent-light;

      &::placeholder {
        color: $text-light;
        @include fontRegular14;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px $accent-light inset;
        -webkit-text-fill-color: $accent-main;
      }
    }

    & fieldset {
      border-radius: 8px;
      border: 1px solid $accent-add;
      border-color: $accent-add;
    }
  }
}

.errorMessage {
  color: rgba(253, 37, 81, 1);
  margin-top: 4px;
  margin-left: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiOutlinedInput-root {
  width: 100%;
  border-radius: 8px !important;
}