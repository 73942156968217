@import '../../../styles/utils/variables';

.tooltip-email-right {
  position: absolute;
  right: -240px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 12px;
  gap: 8px;
  width: 222px;
  height: 102px;
  background: $text-middle;
  border-radius: 4px;
  font-size: 12px;
  color: $white;
  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: -5px;
    top: 30px;
    background: $text-middle;
    border-radius: 3px;
    transform: rotate(45deg);
    z-index: 200;
  }
}
.tooltip-email-bottom {
  position: absolute;
  right: 0px;
  bottom: -130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 36px;
  gap: 8px;
  width: 302px;
  height: 102px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 2000;
  background: #f5f5f5;
  color: #6c757d;
  border-radius: 8px;

  .infoIconWrapper {
    position: absolute;
    top: 23px;
    left: 13px;
    z-index: 2000;
  }
}
