.post {
  &__header-more-btn-container {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 1px 4px rgba(91, 29, 114, 0.1);
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;

    transition: background-color 0.2s linear;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: #FBF5FF;
    }
  }

  &__header-more-btn {
    fill: #6C757D;
    transition: fill 0.2s linear;

    &:hover {
      fill: #620C6D;
    }

    &:hover svg path{
      fill: #620C6D;
    }
  }

  &__popover-container {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 19px;
    color: #6C757D;
  }

  &__popover-row {
    padding: 8px 18px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;

    &:hover {
      fill: #363636;
      color: #363636;
      background: #F5F5F5;
    }

    &:hover .post__popover-img {
      fill: #363636;
      color: #363636;
    }
  }

  &__popover-img {
    fill: #6C757D;

    &:hover {
      fill: #363636;
      color: #363636;
    }
  }
}