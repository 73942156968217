.post {
  &__attachment-container {
    width: 32%;
    height: 343px;
    max-height: 384px;
    display: flex;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    @media screen and (min-width: 1100px) {
      max-height: 600px;
    }
  }

  &__attachment-item {
    object-fit: contain;
    height: 100%;
    width: 100%;

    &--group {
      object-fit: cover;
    }
  }
}
