@import '../../styles/utils/variables';

.Spinner {
  width: 65%;
  height: 30px;
  position: relative;
  filter: url('../../images/filter-spinner.svg');
  margin: 15px auto;

  >* {
    position: absolute;
    display: inline-block;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: $text-light;
    top: 25%;
    border-radius: 50%;
    animation: loading 4s infinite;
    transform: scale(0.1);
    opacity: 0;
  }

  >*:nth-child(1) {
    animation-delay: 0.5s;
  }

  >*:nth-child(2) {
    animation-delay: 1s;
  }

  >*:nth-child(3) {
    animation-delay: 1.5s;
  }

  >*:nth-child(4) {
    animation-delay: 2s;
  }
}

@keyframes loading {
  50% {
    transform: scale(1.25);
    left: 50%;
    opacity: 1;
  }

  100% {
    transform: scale(0.1);
    left: 100%;
    opacity: 0;
  }
}