@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.PodcastsModal {
  &__container {
    min-height: 624px;
    // max-height: 680px;
    padding: 16px;
    column-gap: 16px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    @media screen and (min-width: 1100px) {
      display: grid;
      grid-template-columns: minmax(670px, 750px) minmax(322px, 342px);
      max-height: 680px;
    }
    .MuiTouchRipple-root {
      color: $accent-add;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &-text {
      @include fontSemibold18;
      color: $text-dark;
    }

    > svg.fire {
      color: $red;
    }

    > svg.rocket {
      color: $accent-main;
    }
  }

  &__topic,
  &__selectShow {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }

  &__iframe {
    border-radius: 12px;
    border: 1px solid $accent-light;
    width: 100%;

    &-trends,
    &-episode {
      height: 82px;
    }
  }

  &__trends {
    margin-top: 10px;
    grid-column: 2/3;
    grid-row: 1/2;
    @media screen and (min-width: 1100px) {
      margin-top: 0px;
    }

    &-item {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 4px;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      > .secondaryButton {
        width: 100%;
      }
    }
  }

  &__backButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2.5px 0 0;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;
    @media screen and (min-width: 1100px) {
      flex-direction: row;
      width: 100%;
      min-width: 334px;
      height: 40px;
    }

    .searchInput {
      @media screen and (min-width: 1100px) {
        width: 100%;
        min-width: 334px;
        height: 40px;
      }
    }
  }

  &__choice {
    grid-column: 1/2;
    grid-row: 1/2;

    .emptyShow {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__text {
        @include fontRegular14;
        color: $text-light;
      }
    }

    .MuiTabs-root {
      height: 200px;
      min-width: 300px;
      max-width: 300px;
      width: 100%;
      @media screen and (min-width: 1100px) {
        min-width: 670px;
        max-width: 750px;
      }
    }

    .MuiTabs-flexContainer {
      gap: 8px;
    }

    .MuiTab-root.ShowView {
      @include fontRegular14;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      height: 200px;
      padding: 8px;
      border-radius: 8px;
      transition: $transition;

      &:hover {
        padding: 6px;
        cursor: pointer;
        background-color: $white;

        .ShowView__cover {
          width: 124px;
          height: 124px;
        }
      }

      &.Mui-selected {
        border: 1px solid $accent-main;
      }
    }

    .ShowView {
      &__cover {
        width: 120px;
        height: 120px;
        max-width: none;
        border-radius: 8px;
        transition: $transition;
      }

      &__name {
        @include fontSemibold14;
        color: $text-dark;
        text-transform: capitalize;
        max-width: 120px;
        max-height: 60px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  &__episodes {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    &-list {
      min-width: 300px;
      max-width: 300px;
      width: 100%;
      padding-right: 4px;
      overflow-y: auto;
      @media screen and (min-width: 1100px) {
        min-width: 670px;
        max-width: 750px;
      }
    }

    .EpisodesInfo {
      min-width: 300px;
      max-width: 300px;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      gap: 34px;
      @media screen and (min-width: 1100px) {
        min-width: 670px;
        max-width: 750px;
      }

      &__podcastInfo {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
      }

      &__podcastName {
        @include fontSemibold16;
        color: $accent-main;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__total {
        @include fontRegular14;
        color: $text-light;

        .count {
          @include fontRegular16;
          color: $text-light;
        }
      }
    }
  }
}
