@import '../../../../styles/utils/variables';

.onboardingForm {
  width: 327px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  background: $white;
  box-shadow: 0px 4px 15px #f2f2f2;
  border-radius: 12px;

  @media screen and (min-width: 1100px) {
    margin-bottom: 64px;
    width: 894px;
    margin-top: 0px;
  }

  &--notOnboarding {
    margin: 0;
    width: 100%;
  }

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 24px;
  }

  &-footer {
    display: flex;
    margin-top: 24px;

    & > button:first-child {
      margin-right: 16px;
    }
  }

  &-header {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 24px;
    margin-bottom: 30px;
    border-bottom: 1px solid $stroke;

    &__accent {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: $accent-main;
    }
  }

  &-text {
    width: 270px;
    font-size: 14px;
    margin-bottom: 24px;
    text-align: center;

    @media screen and (min-width: 1100px) {
      width: 846px;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
    }

    & > div:first-child {
      margin-bottom: 16px;

      @media screen and (min-width: 1100px) {
        margin-right: 16px;
      }
    }
  }

  &-list {
    &-item {
      margin-bottom: 4px;
    }

    margin-bottom: 30px;

    &__other {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media screen and (min-width: 1100px) {
        flex-direction: row;
        align-items: center;
      }

      & > label {
        margin-bottom: 4px;
        margin-right: 0px;

        @media screen and (min-width: 1100px) {
          margin-bottom: 0px;
          margin-right: 8px;
        }
      }
    }
  }

  &-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    & > div:first-child {
      @media screen and (min-width: 1100px) {
        margin-right: 0px;
      }
    }
  }

  &-chips {
    margin-bottom: 8px;

    & div {
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }

  &-photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 12px;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
      // flex-wrap: nowrap;
      // justify-content: space-between;
    }

    & > label {
      margin-right: 8px;
      margin-bottom: 8px;

      @media screen and (min-width: 1100px) {
        margin-right: 24px;
        margin-bottom: 12px;
      }
    }
  }

  &-upload {
    display: flex;
    margin-bottom: 24px;

    &__img {
      width: 100px;
      height: 100px;
      border: 1px solid $text-light;
      background: $accent-light;
      border-radius: 50%;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: 1100px) {
        width: 110px;
        height: 110px;
      }

      & > svg {
        color: $text-light;
        pointer-events: none;
      }

      &--drag {
        border: 1px dashed $accent-main;
        background: #78118631;

        & > svg {
          color: $accent-main;
        }
      }
    }

    &__avatar {
      border: 1px solid $text-light;
      width: 110px;
      height: 110px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 16px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 130px;

      @media screen and (min-width: 1100px) {
        width: 300px;
      }
    }

    &__space {
      width: 20px;
      display: inline-block;

      @media screen and (min-width: 1100px) {
        display: none;
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__row {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (min-width: 1100px) {
        flex-direction: row;
      }

      & label {
        margin-right: 0px;
        margin-top: 12px;
        margin-bottom: 12px;

        @media screen and (min-width: 1100px) {
          margin-right: 16px;
        }
      }
    }

    & span {
      margin-top: 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $text-middle;
    }
  }

  &__interest-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6c757d;
  }
}
