@import '../../../styles/utils/mixin';

.comments {
  &-container {
    padding: 6px 0;
    // height: 100%;
    max-width: 500px;
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    position: relative;

    @media screen and (min-width: 380px) {
      padding: 6px 8px;
    }

    @media screen and (min-width: 1100px) {
      padding: 16px;
      max-width: 753px;
    }
  }

  &-avatar {
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &-wrapper {
    width: 100%;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;

    @media screen and (min-width: 320px) {
      min-width: 200px;
    }

    @media screen and (min-width: 370px) {
      min-width: 225px;
    }

    @media screen and (min-width: 420px) {
      max-width: 650px;
    }
  }

  &-content {
    width: 100%;
    min-width: 205px;
    max-width: 100%;
    word-wrap: break-word;
    padding: 8px;
    background: #f8f8f8;
    border-radius: 8px;

    @media screen and (min-width: 370px) {
      min-width: 217px;
    }

    @media screen and (min-width: 1100px) {
      min-width: 220px;
      max-width: 700px;
    }
  }

  &-name-date-container {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &-name {
    position: relative;
    @include fontSemibold14;
    color: #363636;
    transition: 0.2s linear;

    &:hover {
      color: #791186;
      text-decoration: line;
    }
  }

  &-date {
    font-family: inherit;
    font-size: 12px;
    line-height: 16px;
    color: #6c757d;

    @media screen and (min-width: 1100px) {
      margin-left: 4px;
    }
  }

  &-text {
    @include fontRegular14;
    margin-top: 8px;
    color: #000000;
    width: 177px;
    overflow-wrap: break-word;

    @media screen and (min-width: 400px) {
      width: 200px;
    }

    @media screen and (min-width: 420px) {
      width: 210px;
    }

    @media screen and (min-width: 1100px) {
      width: 300px;
    }
  }

  &-likes-container {
    display: flex;
    flex-wrap: wrap;
  }

  &-likes-text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
    margin-right: 8px;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    &:hover {
      & span {
        color: #791186;
      }

      & path {
        fill: #791186;
      }
    }

    &:last-child {
      margin-right: 0 !important;
    }

    @media screen and (min-width: 1100px) {
      margin-right: 14px;
    }
  }

  &-likes-text-wrapper.sameUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
    margin-right: 8px;
    cursor: pointer;

    & span {
      color: #791186;
    }

    & path {
      fill: #791186;
    }

    @media screen and (min-width: 1100px) {
      margin-right: 18px;
    }
  }

  &-likes-icon {
    fill: #6c757d;
  }

  &-likes-text {
    margin-left: 4px;
    @include fontRegular12;
    white-space: nowrap;
    color: #363636;

    &:hover {
      color: #791186;
    }
  }
}

.comments-MoreHorizIcon-wrapper {
  margin-left: 12px;
  width: 40px;
  height: 40px;

  @media screen and (min-width: 1100px) {
    margin-left: 16px;
  }
}

.comments {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  column-gap: 12px;

  &-avatar-container {
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    @media screen and (min-width: 1100px) {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
    }
  }

  &-avatar {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: inherit;
    height: inherit;
    min-width: inherit;
    min-height: inherit;
    transform: translate(-50%, 50%);
    object-fit: cover;
    border-radius: 50%;
  }

  &-input-container {
    margin-left: 16px;
    position: relative;
    flex-grow: 1;
  }

  &-input {
    padding: 11px 50px 11px 16px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #a7a7a7;
    border: 1px solid #e9e9e9;
    border-radius: 8px;

    &:focus {
      outline: 1px solid #fadcff;
      color: #791186;
      background-color: #fbf5ff;
    }

    &::placeholder {
      color: #a7a7a7;
    }
  }

  &-send {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &:hover {
      fill: #620c6d;
    }
  }
}

.write-subcomment {
  margin-left: 60px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  column-gap: 12px;

  &-avatar-container {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    min-width: 32px;
    min-height: 32px;
  }

  &-avatar {
    position: absolute;
    bottom: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    transform: translate3d(-50%, 50%, 0);
    border-radius: 50%;
  }

  &-input-container {
    position: relative;
    flex-grow: 1;
  }

  &-input {
    padding: 11px 50px 11px 16px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #a7a7a7;
    border: 1px solid #e9e9e9;
    border-radius: 8px;

    &:focus {
      outline: 1px solid #fadcff;
      color: #791186;
      background-color: #fbf5ff;
    }

    &::placeholder {
      color: #a7a7a7;
    }
  }

  &-send {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &:hover {
      fill: #620c6d;
    }
  }
}
