.BackToTopButton {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  padding: 12px 14px;
  background: #fbf5ff;
  box-shadow: 0px 1.25px 5px rgba(91, 29, 114, 0.1);
  border: 1px solid #fbf5ff;
  border-radius: 50%;
  z-index: 999;
  cursor: pointer;
  @media screen and (min-width: 1000px) {
    right: (30%);
  }
  @media screen and (min-width: 1250px) {
    right: (31%);
  }
  @media screen and (min-width: 1300px) {
    right: (31%);
  }
  @media screen and (min-width: 1100px) {
    right: (32%);
  }
  @media screen and (min-width: 1400px) {
    right: (33%);
  }
  @media screen and (min-width: 1100px) {
    right: (20%);
  }
  @media screen and (min-width: 1465px) {
    right: (21%);
  }
  @media screen and (min-width: 1500px) {
    right: (22%);
  }
  @media screen and (min-width: 1550px) {
    right: (23%);
  }
  @media screen and (min-width: 1600px) {
    right: (24%);
  }
  @media screen and (min-width: 1600px) {
    right: (25%);
  }
  @media screen and (min-width: 1700px) {
    right: (26%);
  }
  @media screen and (min-width: 1800px) {
    right: (27%);
  }
  @media screen and (min-width: 1900px) {
    right: (28%);
  }
}
.BackToTopButton:hover,
.BackToTopButton:focus {
  background: #fadcff;
  border: 1px solid #fadcff;
}
.arrowIcon {
  fill: #791186;
  width: 20px;
  height: 20px;
}
.BackToTopButton .arrowIcon:hover,
.BackToTopButton .arrowIcon:focus {
  fill: #620c6d;
}
