@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.SpotifyModal {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 40px 60px;
    @media screen and (min-width: 1100px) {
      flex-direction: row;
    }
  }

  &__button {
    padding: 24px 40px;
    border-radius: 22px;
    transition: $transition;

    &:hover {
      background-color: $white;
    }

    &-image {
      margin-bottom: 10px;
    }

    &-text {
      @include fontSemibold18;
      color: $accent-main;
      text-align: center;
    }
  }
}
