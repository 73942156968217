@import '../../../styles/utils/variables';

.authContainerWrapper-newPassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 650px;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    height: 100%;
  }
}

.newPasswordFormWrapper {
  width: 352px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  border-radius: 12px;
  box-shadow: $shadow;
  background-color: $white;
  z-index: 1;
  @media screen and (min-width: 1100px) {
    width: 448px;
  }
}
