@import '../../../styles/utils/mixin';

.reportedComments {
  &-container {
    margin-top: 12px;
    padding: 6px 8px;
    // height: 100%;
    max-width: 500px;
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    position: relative;

    @media screen and (min-width: 1100px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px;
      max-width: 785px;
    }
  }

  &-avatar {
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;
    max-width: 700px;
  }

  &-content {
    // min-width: 220px;
    width: 100%;
    padding: 8px;
    background: #f8f8f8;
    border-radius: 8px;

    @media screen and (min-width: 1100px) {
      min-width: 220px;
      max-width: 700px;
    }
  }

  &-name-date-container {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &-name {
    position: relative;
    @include fontSemibold14;
    color: #363636;
    transition: 0.2s linear;

    &:hover {
      color: #791186;
      text-decoration: line;
    }
  }

  &-date {
    @include fontSemibold12;
    color: #6c757d;

    @media screen and (min-width: 1100px) {
      margin-left: 4px;
    }
  }

  &-text {
    @include fontRegular14;
    margin-top: 8px;
    color: #000000;
    max-width: 600px;
    overflow-wrap: break-word;
  }

  &-likes-container {
    display: flex;
  }

  &-likes-text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
    margin-right: 18px;
  }

  &-likes-text-wrapper.sameUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
    margin-right: 18px;
  }

  &-likes-icon {
    fill: #6c757d;
  }

  &-likes-text {
    margin-left: 6px;
    @include fontSemibold12;
    color: #363636;
  }

  &-lower-buttons-wrapper {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-delete-comment-btn {
    margin-left: 12px;
  }

  &-report-reasons-modal {
    margin-left: 12px;
    @include fontRegular14;
    color: #6c757d;
    cursor: pointer;

    &:hover {
      color: #791186;
    }
  }
}

.reportedComments {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  column-gap: 12px;

  &-avatar-container {
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    @media screen and (min-width: 1100px) {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
    }
  }

  &-avatar {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: inherit;
    height: inherit;
    min-width: inherit;
    min-height: inherit;
    transform: translate(-50%, 50%);
    object-fit: cover;
    border-radius: 50%;
  }
}
