@import '../../styles/utils/mixin';
@import '../../styles/utils/variables';

.post-report-page {
  min-width: 500px;
  max-width: 610px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 282px;

  @media screen and (min-width: 1440px) {
    margin: 0 20px;
    min-width: 785px;
    max-width: 785px;
    margin-left: 344px;
  }

  &__title {
    @include fontSemibold18;
    margin-bottom: 12px;
    text-transform: capitalize;
    color: $text-dark;
  }
}