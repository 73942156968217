@import '../../../styles/utils/variables';

.selectWithIcons {

  & .Mui-focused {
    & fieldset {
      border-width: 1px !important;
      border-color: $accent-add !important;
    }
  }

  & .MuiSelect-icon {
    width: 18px;
    height: 18px;
    color: $accent-main;
    right: 14px;
    top: 11px;
  }

  &:hover {
    & fieldset {
      border-color: $accent-add !important;
    }
  }

  & fieldset {
    border-color: $stroke;
  }
}

#select-with-icons {
  width: 188px;
  padding: 9px 16px;
  height: 19px;

  & .option {
    color: $accent-main;
    font-size: 14px;

    &>svg {
      margin-right: 8px;
    }
  }
}

.MuiAutocomplete-noOptions {
  font-size: 14px;
  color: $text-middle;
}

.MuiList-root[aria-labelledby="select-with-icons"] {

  .option {
    & svg {
      margin-right: 8px;
    }
  }

  & li {
    color: $text-middle;
    font-size: 14px;
  }

  & li[aria-selected="true"] {
    background-color: transparent;
    color: $accent-main;
    position: relative;

    &::after {
      content: '';
      width: 9px;
      height: 5px;
      transform: rotate(135deg);
      border-top: 2px solid green;
      border-right: 2px solid green;
      position: absolute;
      right: 18px;
      bottom: 16px;
    }

    &.Mui-focused {
      background-color: $accent-light;
    }
  }
}

.MuiPaper-selectWithInputs{
  border: 1px solid $stroke;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
}