@import '../../../styles/utils/mixin';

.write-subcomment {
  margin-left: 60px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  column-gap: 12px;

  &-avatar-container {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  &-avatar {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: inherit;
    height: inherit;
    transform: translate(-50%, 50%);
    object-fit: cover;
    border-radius: 50%;
  }

  &-input-container {
    position: relative;
    flex-grow: 1;
  }

  &-input {
    padding: 11px 50px 11px 16px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #a7a7a7;
    border: 1px solid #e9e9e9;
    border-radius: 8px;

    &:focus {
      outline: 1px solid #fadcff;
      color: #791186;
      background-color: #fbf5ff;
    }

    &::placeholder {
      color: #a7a7a7;
    }
  }

  &-send {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &:hover {
      fill: #620c6d;
    }
  }
}

// ==================Subcomments=========================

.subcommentShow {
  &-show-subcomments-text {
    margin-left: 60px;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: #791186;
    // width: 80px;
  }

  &-show-subcomments-btn {
    margin-left: 60px;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: #791186;
    cursor: pointer;
  }
}

.subcomments {
  &-container {
    margin-left: 45px;
    padding: 16px 16px 16px 0;
    max-width: 785px;
    display: flex;
    flex-direction: row;
    background: #ffffff;
    border-radius: 8px;
    position: relative;

    @media screen and (min-width: 1100px) {
      padding: 16px;
    }
  }

  &-avatar-container {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    @media screen and (min-width: 1100px) {
      min-width: 32px;
      height: 32px;
    }
  }

  &-avatar {
    position: absolute;
    bottom: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    transform: translate3d(-50%, 50%, 0);
    border-radius: 50%;

    @media screen and (min-width: 1100px) {
      // height: 32px;
    }
  }

  &-wrapper {
    margin-left: 12px;
    margin-right: 12px;
    max-width: 580px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: 1100px) {
      margin-right: 16px;
    }
  }

  &-content {
    width: 100%;
    padding: 8px;
    background: #f8f8f8;
    border-radius: 8px;
  }

  &-name-date-container {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &-name {
    position: relative;
    @include fontSemibold14;
    color: #363636;
    transition: 0.2s linear;
    cursor: pointer;

    &:hover {
      color: #791186;
      text-decoration: line;
    }
  }

  &-date {
    font-family: inherit;
    font-size: 12px;
    line-height: 16px;
    color: #6c757d;

    @media screen and (min-width: 1100px) {
      margin-left: 4px;
    }
  }

  &-text {
    @include fontRegular14;
    margin-top: 8px;
    color: #000000;
    max-width: 580px;
    overflow-wrap: break-word;
  }

  &-likes-container {
    display: flex;
    flex-wrap: wrap;
  }

  &-likes-text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
    margin-right: 12px;
    // margin-right: 18px;
    cursor: pointer;

    &:hover {
      & span {
        color: #791186;
      }

      & path {
        fill: #791186;
      }
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }

  &-likes-text-wrapper.sameUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
    margin-right: 18px;
    cursor: pointer;

    & span {
      color: #791186;
    }

    & path {
      fill: #791186;
    }
  }

  &-likes-text-wrapper.sameUserId {
    display: none;
  }

  &-likes-icon {
    fill: #6c757d;
  }

  &-likes-text {
    margin-left: 6px;
    @include fontSemibold12;

    color: #363636;
    white-space: nowrap;

    &:hover {
      color: #791186;
    }
  }
}
