@import '../utils/variables';

@mixin fontSemibold12 {
  font-family: inherit;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

@mixin fontSemibold13 {
  font-family: inherit;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: $text-dark;
}

@mixin fontSemibold14 {
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

@mixin fontSemibold16 {
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

@mixin fontSemibold18 {
  font-family: inherit;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

@mixin fontSemibold20 {
  font-family: inherit;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

@mixin fontSemibold22 {
  font-family: inherit;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

@mixin fontSemibold32 {
  font-family: inherit;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
}

@mixin fontRegular12 {
  font-family: inherit;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin fontRegular13 {
  font-family: inherit;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
}

@mixin fontRegular14 {
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

@mixin fontRegular16 {
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

@mixin fontRegular18 {
  font-family: inherit;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

@mixin fontsMedium16 {
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@mixin relaxRoomButton {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border-width: 0;
  border-radius: 50%;
  z-index: 1;

  svg {
    z-index: 3;
    width: 32px;
    height: 32px;
    animation: growHand 2s ease-in-out infinite;
    transition: transform .5s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    cursor: pointer;
    transform: rotate(-12deg);
  }

  &::after,
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $accent-add;
    animation: grow 2s ease-in-out infinite;
    z-index: -1;
  }

  &::after {
    background: rgba(250, 220, 255, 0.3);

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      animation: grow 1s ease-in-out infinite;
      z-index: -1;
    }
  }

  &::before {
    background-color: rgba(250, 220, 255, 0.5);
    animation-delay: -0.6s;
  }
}

@keyframes grow {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1.8, 1.8);
    opacity: 0;
  }
}

@keyframes growHand {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 0.2;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 1;
  }
}

@mixin iconWithBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border: 1px solid $white;
  border-radius: 50%;
  background-color: $white;
  cursor: pointer;
  transition: $transition;
  box-shadow: 0 .75px 3px 0 rgba(91, 29, 114, 0.1);

  &:hover {
    background-color: $accent-light;
  }

  &:active {
    background-color: $white;
    border-color: $accent-main;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
    border-color: transparent;

    >svg {
      color: $stroke;
    }

    >svg:hover {
      color: $stroke;
    }
  }

  .MuiSvgIcon-root {
    color: $text-middle;
    width: 18px;
    height: 18px;
    transition: $transition;

    &:hover {
      color: $accent-hover;
    }

    &:active {
      color: $accent-main;
    }

    &:disabled {
      cursor: not-allowed;
      color: $stroke;
    }
  }
}
