@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.ConfirmModal {
  position: relative;

  .MuiSvgIcon-root {
    position: absolute;
    top: -12px;
    right: -12px;
    color: $text-middle;
    transition: $transition;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:hover {
      color: $accent-main;
      transform: rotate(180deg);
    }
  }

  &__text {
    @include fontSemibold18;
    color: $text-dark;
    text-align: center;
  }

  ~.ModalFooter {
    >button {
      flex-grow: 1;
    }
  }
}
