@import '../../../../styles/utils/variables';

.search-accordion-main {
  &--border {
    & > .MuiPaper-root {
      border-bottom: 1px solid $stroke;
    }
  }

  &-rows {
    position: relative;
  }

  &__icon-container {
    width: 24px;
    height: 24px;
    top: 12px;
    right: 30px;
    position: absolute;

    @media screen and (min-width: 1100px) {
      right: 18px;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    position: absolute;
    color: #6c757d;
    z-index: 10;
    transition: transform 0.2s linear;
  }

  &__icon svg {
    position: absolute;
    transition: 0.2s linear;
  }

  &__image {
    // position: absolute;
    margin-right: 10px;
  }

  &__title-container {
    max-width: 257px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0px;
    margin-bottom: 8px;

    &:hover {
      background-color: $tags;
      cursor: pointer;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .MuiCheckbox-root {
      padding: 6px 8px 6px 13px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__innerinfo-name {
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 10px;
  }

  // & > .MuiButtonBase-root:hover {
  //   background-color: aqua;
  // }
}

.MuiAccordionDetails-root.css-s70892-MuiAccordionDetails-root {
  padding-left: 0px;
}
.css-1hqtxqe-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 40px;
}
.css-1betqn-MuiAccordionSummary-content {
  margin: 0px;
}

.accordion-main__icon-container {
  right: 18px;

  @media screen and (max-width: 1440px) {
    right: 4px;
  }

  @media screen and (max-width: 1100px) {
    right: 30px;
  }
}
