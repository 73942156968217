@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.authContainerWrapper-signIn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 725px;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    height: 100%;
  }
  @media screen and (max-width: 1100px) {
    flex-direction: row;
    height: 100%;
  }
}
// .authContainerLogoWrapper {
//   margin-right: 112px;
//   .logoWizwork {
//     width: 126px;
//     height: 158px;
//     margin-bottom: 24px;
//   }
//   .authContainerText {
//     font-family: 'Open Sans';
//     font-weight: 600;
//     font-size: 16px;
//     line-height: 22px;
//     color: $text-dark;
//   }
// }
.authForm {
  .signInFormWrapper {
    width: 352px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    border-radius: 12px;
    // margin-bottom: 30px;
    box-shadow: $shadow;
    background-color: $white;
    border: 3px solid black;
    z-index: 1;
    @media screen and (min-width: 1100px) {
      margin-bottom: 0;
      width: 448px;
    }
    @media screen and (max-width: 1100px) {
      margin-bottom: 0;
      width: auto;
    }
  }
}
.signUpLinkWrapper {
  margin-top: 16px;
  text-align: center;

  .signUpLink {
    margin-top: 12px;
    margin-top: 12px;
    margin-bottom: 16px;
    text-align: end;
    @include fontSemibold14;
    color: $accent-main;
  }
}

.ellipse {
  width: 870px;               /* Set the width of the ellipse */
  height: 870px;              /* Set the height of the ellipse */
  background-color: rgba($color: #ffffff, $alpha: 0.5);  /* Set the background color to white */
  border-radius: 50%;         /* Makes the shape a circle/ellipse */
  position: absolute;         /* Allows precise positioning */
  top: 50%;                   /* Center vertically relative to the nearest positioned ancestor */
  left: 50%;                  /* Center horizontally relative to the nearest positioned ancestor */
  transform: translate(-50%, -50%); /* Adjusts the position to truly center the element */
  z-index: 0;
}

.authWrapper{
  display: flex; 
  z-index: 1;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
}