.onboarding {
  // margin-top: 0px;
  // margin-bottom: 50px;

  &-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 30px;

    & > img{
      width: 350px;
    }
  }
}
