.DataPicker {
  position: relative;

  &__container {
    position: absolute;
    width: 197px;
    right: -85px;
    bottom: -170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 0.649541px 6px rgba(91, 29, 114, 0.1);
    border-radius: 5px;
    z-index: 1;

    @media (max-width: 768px) {
      right: 36px;
      bottom: -242px;
    }

    &--time {
      bottom: -70px;

      @media (max-width: 768px) {
        bottom: -132px;
      }
    }
  }

  &__header {
    position: relative;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFDBE1;
    box-shadow: 0px 0.649541px 6px rgba(91, 29, 114, 0.1);
    border-radius: 5px 5px 0px 0px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #363636;
    margin-bottom: 6px;
  }

  &__btn-close {
    position: absolute;
    right: 6px;
    top: 8px;
    background-image: url('../../images/cross-mini.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 5px;
    height: 5px;
    cursor: pointer;
    z-index: 2;
  }
}

// DATE Picker

.react-datepicker {
  width: 197px;
  border: none;
}

.react-datepicker__header {
  border-radius: 5px 5px 0px 0px;
  background: #FFDBE1;
  width: 197px;
  border: none;
  box-shadow: 0px 0.649541px 6px rgba(91, 29, 114, 0.1);
}

.react-datepicker__month,
.react-datepicker__month-container {
  width: 197px;
}

.react-datepicker__month {
  margin-bottom: 0;
}

.react-datepicker__current-month {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #363636;
}

.react-datepicker__navigation {
  top: 3px;

  &--previous {
    left: 22px;
  }

  &--next {
    right: 22px;
  }
}

.react-datepicker__day-name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    height: 20px;
    width: 20px;
    color: #363636;
}

.react-datepicker__week {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.react-datepicker__day {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  height: 21px;
  width: 21px;
  color: #363636;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected:hover {
  background: #FFDBE1;
  box-shadow: 0px 0.625px 2.5px rgba(91, 29, 114, 0.1);
  border-radius: 50%;
  color: #363636;
}

.react-datepicker__day--disabled {
  color: #7a7979;
}


// TIME Picker 

.react-time-picker__wrapper {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #363636;
  border: none;
}

.react-time-picker__inputGroup__divider {
  white-space: normal;
}

.react-time-picker__inputGroup__input > option {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #363636;
  background: #E9E9E9;
  border-radius: 4px;
  border: none;
  outline: none;
  border-radius: 12px;
}

.react-time-picker__wrapper {
  outline: none;
  border: none;
}

.react-time-picker__button {
  display: none;
}