.privacySettings {
  &-subtitle {
    margin-bottom: 24px;
  }

  &-list {
    display: flex;
    flex-flow: column nowrap;

    >label {
      width: fit-content;
      margin-bottom: 4px;
    }
  }
}
