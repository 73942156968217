@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.container {
  @include fontRegular14;
  color: $text-dark;
  text-align: center;
  box-sizing: border-box;
  position: absolute;
  left: 24px;
  right: auto;
  top: 20px;
  width: 100px;
  z-index: 5;
  transition: background-color 200ms ease 300ms;

  @media screen and (min-width: 1100px) {
    left: auto;
    right: 128px;
    top: 32px;
    width: 154px;
  }

  &:hover {
    transition: all 200ms ease;
  }

  .currentLanguageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;

    @media screen and (min-width: 1100px) {
      cursor: pointer;
    }

    .arrowDown {
      width: 16px;
      height: 16px;
      color: $text-light;

      @media screen and (min-width: 1100px) {
        color: $text-middle;
      }
    }
  }

  .currentLanguageIcon {
    display: flex;
    align-items: center;
  }

  .currentLanguageName {
    @include fontRegular14;
    color: $text-dark;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &:hover {
    transition: transform 300ms ease 100ms;

    .arrowDown {
      color: $text-middle;
      transform: rotate(180deg);
    }

    .optionsContainer {
      height: 328px;
      // height: 235px;
      transition: height 300ms ease;
      padding-top: 10px;
      padding-bottom: 10px;

      .optionWrapper {
        opacity: 1;
        transition: opacity 300ms ease 100ms;
      }
    }
  }

  .optionsContainer {
    background-color: $white;
    border-radius: 5px;
    height: 0;
    overflow: hidden;
    box-sizing: border-box;
    transition: height 300ms ease 100ms;
    box-shadow: 0px 4px 15px 0px #f2f2f2;
  }

  .optionWrapper {
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $tags;
      transition: background-color 300ms ease;
    }

    .optionIcon {
      padding-left: 12px;
      display: flex;
      align-items: center;

      @media screen and (min-width: 1100px) {
        padding-left: 24px;
      }
    }

    .optionText {
      @include fontRegular14;
      color: $text-dark;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      height: 44px;
      width: 154px;
      margin-left: 8px;
      transition: $transition;

      &.selected {
        @include fontSemibold14;
      }
    }
  }
}
