.AdsModal {

  &-line {
    height: 1px;
    width: 100%;
    background-color: #E9E9E9;
    margin-bottom: 16px;
  }

  &-main {
    max-height: 512px;
    overflow: auto;
    margin-bottom: 16px;
  }

  &-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid #E9E9E9;
    border-radius: 8px;
    position: relative;

    &__counter{
      position: absolute;
      bottom: 16px;
      right: 18px;
      font-size: 12px;
    }

    &--disabled {
      background: #F8F8F8;

      .AdsModal-item {
        &__title {
          color: #A7A7A7;
        }

        &__text {
          color: #A7A7A7;
        }

        &__btn:not(:first-child) {
          box-shadow: none;
          color: #A7A7A7;
          pointer-events: none;
        }
      }

    }


    &__wrapper {
      display: flex !important;
      align-items: center;
      margin-bottom: 16px;

      &:last-child {
        margin: 0;
      }

      &--drag {
        background: #FFF,
      }
    }

    &__drag {
      color: #6C757D;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        width: 16px;
        height: 16px;
      }

      &--disabled {
        color: #A7A7A7;
      }
    }

    &__left {
      margin-right: 8px;
      min-width: 136px;
      height: 100%;
    }

    &__img {
      width: 136px;
      height: 102px;
      border-radius: 4px;
      object-fit: cover;
      background: #efefef;
      margin-bottom: 8px;
    }

    &__languages {
      display: flex;

      & svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      & svg:last-child {
        margin-right: 0;
      }
    }

    &__main {
      width: 100%;
      max-width: 281px;
      margin-right: 8px;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #363636;
      margin-bottom: 8px;
    }

    &__text {
      color: #6C757D;
    }

    &__menu {
      height: 104px;
      width: 94px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__disabled {
      color: #FD2551;
      display: flex;
      align-items: center;

      &>div {
        margin-left: 8px;
      }
    }

    &__btn {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-shadow: 0px 0.75px 3px rgba(91, 29, 114, 0.1);
      cursor: pointer;
      color: #6C757D;
      transition: all .3s;
      display: flex;
      align-items: center;
      justify-content: center;

      &>svg {
        width: 18px;
        height: 18px;
      }

      &:hover {
        color: #791186;
      }
    }
  }
}

.adsCreation {

  &__img {
    width: 356px;
    height: 208px;
    object-fit: cover;

    &-wrapper {
      width: 356px;
      margin-bottom: 12px;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      & svg {
        width: 18px;
        height: 18px;
        color: #6C757D;
        z-index: 10000;
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        transition: all .3s;
        background-color: #fff;
        border-radius: 50%;

        &:hover {
          color: #791186;
          transform: rotate(180deg);
        }
      }
    }
  }

  &__error{
    margin-top: 16px;
    color: #FD2551;
    text-align: center;
  }

  &__lang {
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    &>svg {
      margin-left: 14px;
      cursor: pointer;
      transition: all .3s;
      color: #6C757D;
      width: 18px;
      height: 18px;

      &:hover {
        color: #791186;
        transform: rotate(180deg);
      }
    }
  }
}