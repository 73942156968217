.share-to-feed {

  &--background {
    background-color: #E9E9E9;
  }

  &__descr {
    margin-top: 16px;

    & .customTextarea {
      margin-bottom: 12px;
    }

    & textarea {
      height: 133px;
    }
  }

  &__post__container {
    padding: 8px 24px;
  }

  &__post__body-text-container {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
