@import '../../../styles/utils/variables';

.searchInput {
  height: 32px;
  width: 247px;
  padding-left: 16px;
  padding-right: 20px;
  display: block;
  background: $accent-light;
  border-radius: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $accent-main;
  outline: none;
  border: 1px solid transparent;

  @media screen and (min-width: 1100px) {
    width: 176px;
  }
  @media screen and (min-width: 1430px) {
    width: 210px;
  }

  &::placeholder {
    color: $text-middle;
  }

  &:hover {
    border-color: $accent-add;
  }

  &:focus {
    color: $accent-main;
    border-color: $accent-add;
  }

  &-wrapper {
    position: relative;
    color: $text-middle;

    .searchInputIcon {
      // display: none;
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 1;

      @media screen and (min-width: 1100px) {
        display: initial;
      }
    }

    &--filled {
      color: $accent-main;
    }
  }
}
