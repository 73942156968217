@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.mainButton {
  @include fontSemibold14;
  background: $accent-main;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $white;
  padding: 12px 16px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  transition: all .3s;
  cursor: pointer;

  &--bigPadding {
    padding: 12px 49px;
  }

  &:focus,
  &:hover {
    background-color: $accent-hover;
  }

  &[disabled] {
    background: $stroke;
    pointer-events: none;
  }
}