@import '../../../styles/utils/variables';

.checkbox-wrapper {
  display: inline-flex;
  align-items: center;
  padding: 9px 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  background: $accent-light;
  border-radius: 8px;
}