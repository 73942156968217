@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.forgotPasswordWrapper {
  display: flex;
  justify-content: center;
  width: 300px;
  margin-top: 24px;
  padding-bottom: 16px;
  // border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid $stroke;
  margin-bottom: 16px;
  @media screen and (min-width: 1100px) {
    width: 400px;
  }

  .forgotPassword {
    @include fontSemibold22;
    color: $text-dark;
  }
}
.forgotPasswordText {
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: rgba(108, 117, 125, 1);
  @media screen and (min-width: 1100px) {
    font-size: 16px;
  }
}

.forgotPasswordLink {
  margin-top: 12px;
  margin-bottom: 16px;
  text-align: end;
  @include fontSemibold14;
  color: $accent-main;
}
