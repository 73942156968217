.notification {
  background-color: #fff;
  transform: translateY(-8px);
  // height: calc(100vh - 50px);
  border-radius: 0 0 8px 8px;

  @media screen and (max-width: 1100px) {
    transform: translateY(0);
    height: auto;
    border-radius: 0;
  }
}
