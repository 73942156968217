.photo-tab {
  display: flex;
  flex-direction: column;

  &__title {
    margin-left: 24px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;

    @media screen and (min-width: 1100px) {
      margin-left: 0;
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__block {
    padding: 12px;
    background-color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border-radius: 8px;
  }

  &__grid {
    margin-bottom: 12px;
    padding: 12px 24px;
    max-width: 375px;
    align-self: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    background-color: #fff;

    @media screen and (min-width: 1100px) {
      padding: 12px;
      min-width: 610px;
      max-width: 610px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
      border-radius: 8px;
    }

    @media screen and (min-width: 1440px) {
      grid-template-columns: repeat(4, 1fr);
      max-width: 785px;
    }

    &-item {
      width: 157px;
      height: 157px;
      position: relative;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);

      @media screen and (min-width: 1100px) {
        width: 181px;
        height: 181px;
      }

      &-btn {
        display: none;
        width: 24px;
        height: 24px;
        background-color: #fff;
        box-shadow: 0px 0.75px 3px rgba(91, 29, 114, 0.1);
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 5;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;

        &:hover {
          background: #fbf5ff;

          & svg {
            color: #791186;
          }
        }

        & svg {
          transition: all 0.3s;
          width: 18px;
          height: 18px;
          color: #6c757d;
        }

        @media screen and (min-width: 1100px) {
          display: flex;
        }
      }

      &-img {
        width: 157px;
        height: 157px;
        border-radius: 8px;
        object-fit: cover;

        @media screen and (min-width: 1100px) {
          width: 181px;
          height: 181px;
        }
      }

      &-time {
        padding: 2px;
        position: absolute;
        right: 8px;
        bottom: 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #fff;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }
}
