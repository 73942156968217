@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.authContainerWrapper-forgotPassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 650px;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    height: 100%;
  }
}

.forgotPasswordFormWrapper {
  width: 352px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  border-radius: 12px;
  box-shadow: $shadow;
  background-color: $white;
  z-index: 1;
  @media screen and (min-width: 1100px) {
    width: 448px;
  }
}

.forgotPasswordLinkWrapper {
  margin-top: 16px;
  text-align: center;
  color: rgba(108, 117, 125, 1);

  .forgotPasswordLink {
    margin-top: 12px;
    margin-top: 12px;
    margin-bottom: 16px;
    text-align: end;
    @include fontSemibold14;
    color: $accent-main;
  }
}

.sendPasswordWrapper {
  width: 332px;
  height: 266px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 12px;
  box-shadow: $shadow;
  background-color: $white;
  z-index: 1;
}

.checkEmailWrapper {
  display: flex;
  justify-content: center;
  width: 284px;
  margin-top: 24px;
  padding-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid $stroke;
  margin-bottom: 16px;
}

.checkEmail {
  @include fontSemibold22;
  color: $text-dark;
}
