@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.post {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  color: #363636;
  overflow: hidden;

  &__type {
    margin-bottom: 12px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 6px;
    background-color: #791186;
  }

  &__type-img {
  }

  &__type-title {
    @include fontSemibold16;
    color: #fff;
  }

  &__container {
    padding: 16px;
  }

  &__header {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }

  &__header-user-container {
    display: flex;
  }

  &__header-avatar-container {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    @media screen and (min-width: 1100px) {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
    }

    &--shared {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;

      @media screen and (min-width: 1100px) {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
      }
    }
  }

  &__header-avatar-container-inside {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;

    @media screen and (min-width: 1100px) {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
    }
  }

  &__header-avatar {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: inherit;
    height: inherit;
    min-width: inherit;
    min-height: inherit;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    object-fit: cover;
  }

  &__header-date-container {
    display: flex;
    flex-direction: column;

  }

  &__header-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    max-width: 188px;
    width: max-content;
    position: relative;
    font-weight: 600;
    font-size: 16px;
    color: #363636;
    transition: 0.2s linear;
    cursor: pointer;

    & > div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    @media screen and (min-width: 1100px) {
      max-width: 500px;
      font-size: 22px;
    }

    &::after {
      width: 0%;
      position: absolute;
      left: 0;
      bottom: 4px;
      content: '';
      height: 1px;
      background-color: transparent;
      transition: 0.2s linear;
    }

    &:hover {
      color: #791186;
      text-decoration: line;

      &::after {
        background-color: #791186;
        width: 100%;
      }
    }
  }

  &__header-date {
    font-size: 12px;
    line-height: 16px;

    @media screen and (min-width: 1100px) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__header-more-btns-container {
    display: flex;
    column-gap: 16px;
  }

  &__header-admin-note-container {
    margin-bottom: 12px;
    height: 23px;
    width: fit-content;
    min-width: 150px;
    font-size: 14px;
    line-height: 19px;
    color: #363636;
    padding: 2px 6px;
    background: #fbf5ff;
    border-radius: 6px;
  }

  &__body {
  }

  &__advocacy-title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }

  &__body-text-container {
    margin-bottom: 12px;
    padding-bottom: 9px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e9e9e9;
  }

  &__write-comment {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  &__write-comment-avatar-container {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &__write-comment-avatar {
    position: absolute;
    bottom: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    transform: translate3d(-50%, 50%, 0);
    border-radius: 50%;
  }

  &__write-comment-input-container {
    position: relative;
    flex-grow: 1;
  }

  &__write-comment-input {
    padding: 11px 50px 11px 16px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #a7a7a7;
    border: 1px solid #e9e9e9;
    border-radius: 8px;

    &:focus {
      outline: 1px solid #fadcff;
      color: #791186;
      background-color: #fbf5ff;
    }

    &::placeholder {
      color: #a7a7a7;
    }
  }

  &__write-comment-send {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &:hover {
      fill: #620c6d;
    }
  }

  &__footer {
  }

  &__show-comments {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #791186;
  }

  &__report-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }

  &__report-skip-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 16px;
    background: $accent-light;
    border-radius: 12px;
    @include fontSemibold14;
    color: $accent-main;

    &:hover {
      background: $accent-add;
    }
  }

  &__report-delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
