.faq-contact-us-attached.forMobile {
  margin-top: 40px;

  @media screen and (min-width: 1100px) {
    margin-top: 0px;
  }

  .faq-contact-us-attached-row {
    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.faq-contact-us-row {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
}

.faq-btn-label {
  color: #6c757d;
  margin-bottom: 8px;
}
