@import '../../../styles/utils/variables';

.chip {
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $tags;
  border-radius: 22px;
  padding: 0 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  transition: all .3s;
  border: 2px solid $tags;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:hover {
    border-color: $accent-main;
    background: $accent-light;
  }

  &>img {
    width: 28px;
    height: 28px;
    object-fit: contain;
    margin-right: 6px;
  }

  &--checked {
    background: $accent-hover;
    border-color: $accent-hover;
    color: $white;

    &:hover {
      border-color: $accent-hover;
      background: $accent-hover;
    }
  }
}