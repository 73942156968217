* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow-x: hidden;
  max-width: 100vw;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  margin: auto;
  // font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  background-color: #f6f9fb;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

input {
  outline: none;
}

.css-ahj2mt-MuiTypography-root {
  font-family: inherit !important;
}

.css-1ic61zk-MuiTypography-root {
  @media screen and (min-width: 1100px) and (max-width: 1440px) {
    max-width: 150px;
  }
}
