@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.authContainerWrapper-signUp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 820px;
  padding-top: 50px;

  @media screen and (min-width: 1100px) {
    flex-direction: row;
    height: 100%;
  }
}

.authForm {
  .signUpFormWrapper {
    width: 352px;
    height: 381px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    box-shadow: $shadow;
    background-color: #ffffff;
    border: 3px solid black;
    border-radius: 12px;
    @media screen and (min-width: 1100px) {
      width: 448px;
    }
    @media screen and (max-width: 1100px) {
      width: auto;
    }
  }
}

.signUpTextWrapper {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  text-align: center;
  @media screen and (min-width: 1100px) {
    margin-left: 0;
    margin-right: 0;
    width: 400px;
  }
}
.signUpText {
  @include fontRegular12;
  color: #6c757d;
}

.signUpLink {
  @include fontRegular12;
  color: $text-dark;
}

.ellipse {
  width: 870px;               /* Set the width of the ellipse */
  height: 870px;              /* Set the height of the ellipse */
  background-color: rgba($color: #ffffff, $alpha: 0.5);  /* Set the background color to white */
  border-radius: 50%;         /* Makes the shape a circle/ellipse */
  position: absolute;         /* Allows precise positioning */
  top: 50%;                   /* Center vertically relative to the nearest positioned ancestor */
  left: 50%;                  /* Center horizontally relative to the nearest positioned ancestor */
  transform: translate(-50%, -50%); /* Adjusts the position to truly center the element */
  z-index: 0;
}


.authWrapper{
  display: flex; 
  z-index: 1;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
}