@import '../../styles/utils/mixin';
@import '../../styles/utils/variables';

.RelaxRoomPage {
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    opacity: 0.5;
    z-index: -1;
    // display: none;
    @media screen and (min-width: 1100px) {
      // display: block;
      position: static;
      object-fit: fill;
      height: 100vh;
      width: 100%;
      opacity: 1;
    }
  }

  &__btnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 130px;

    @media screen and (min-width: 1100px) {
      padding-top: 0;
    }
  }

  &__spotify {
    margin-bottom: 20px;
    @media screen and (min-width: 1100px) {
      @include relaxRoomButton;
      top: 51%;
      left: 17%;
      display: block;
    }
  }

  &__btnContainer {
    position: relative;
    display: flex;
  }

  &__btnIcon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 30px;
    height: 30px;
    opacity: 0.7;
    fill: #ffffff;
  }

  &__games {
    margin-bottom: 20px;
    @media screen and (min-width: 1100px) {
      @include relaxRoomButton;
      bottom: 13%;
      left: 32%;
      display: block;
    }
  }

  &__books {
    margin-bottom: 20px;
    @media screen and (min-width: 1100px) {
      @include relaxRoomButton;
      bottom: 5%;
      left: 50%;
      display: block;
    }
  }

  &__goBack {
    @media screen and (min-width: 1100px) {
      @include relaxRoomButton;
      top: 12%;
      right: 8%;
      display: block;
    }
  }
}
