@import '../../styles/utils/mixin';

.ProfilesPageTab {
  &__main-title {
    margin-bottom: 12px;
    margin-left: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    align-items: center;

    @media screen and (min-width: 1100px) {
      margin-left: 0;
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__list-container {
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 16px;
    padding: 16px;
    gap: 8px;

    width: 100%;
    box-sizing: border-box;
    border: 1px solid #e9e9e9;
    border-radius: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: 1100px) {
      width: 577px;
      flex-direction: row;
    }

    @media screen and (min-width: 1440px) {
      width: 753px;
    }
  }

  &__img {
    margin-right: 10px;
    border-radius: 50%;
  }

  &__title-wrapper {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__title {
    max-width: 230px;
    min-width: 230px;
    @include fontSemibold18;
    color: #363636;
    cursor: pointer;

    @media screen and (min-width: 1100px) {
      max-width: 400px;
    }
  }

  &__subtitle {
    max-width: 230px;
    @include fontRegular14;
    color: #363636;

    @media screen and (min-width: 1100px) {
      max-width: none;
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__btnWrapper {
    width: 100%;

    @media screen and (min-width: 1100px) {
      width: auto;
    }
  }

  &__container-show-reasons {
    display: flex;
    flex-direction: row;
    gap: 8px;

    margin-top: 8px;
  }

  &__show-reasons {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #791186;
    cursor: pointer;
  }

  &__more-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

.ProfilesPageTab__btnWrapper .mainButton {
  padding: 8px 16px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;

  @media screen and (min-width: 1100px) {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 19px;
  }
}

.ProfilesPageTab__btnWrapper .secondaryButton {
  padding: 8px 16px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;

  @media screen and (min-width: 1100px) {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 19px;
  }
}
