@import '../../../styles/utils/mixin';

.search-nav-side {
  min-width: 100%;
  max-width: 324px;
  // min-width: 324px;
  display: block;
  // display: none;
  // flex-grow: 1;
  // flex-shrink: 1;
  color: #363636;

  @media screen and (min-width: 1100px) and (max-width: 1440px) {
    max-width: 275px;
    min-width: 275px;
    display: block;
    position: fixed;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 92px);
  }

  @media screen and (min-width: 1440px) {
    max-width: 324px;
    min-width: 324px;
    display: block;
    position: fixed;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 92px);
  }

  &__title {
    @include fontSemibold18;

    // @media screen and (min-width: 1100px) {
    //   padding: 0;
    // }
  }

  &__section {
    margin-bottom: 20px;
    padding: 12px;
    background-color: #fff;
    border-radius: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    &-title {
      @include fontSemibold16;
      color: #363636;
      margin-bottom: 12px;
    }
    // &-list{

    // }
    &-list-item {
      display: flex;
      align-items: center;
      margin-left: 16px;
      margin-bottom: 12px;
    }
    &-icon {
      margin-left: 8px;
    }
    &-text {
      margin-left: 8px;
    }
  }

  &__section-info {
    position: relative;
    padding: 12px 0;
    // transition: transform 2s ease-in-out;
  }

  &__section-img {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  &__section-name {
    padding-left: 45px;
    @include fontSemibold16;
    color: #363636;
  }

  &__section-expand-more-icon {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
    &:hover {
      svg {
        fill: #791186;
      }
    }
  }

  &__section-info.open &__section-expand-more-icon {
    transform: rotate(180deg);
  }

  &__section-innerinfo-wrapper {
    margin-top: 2px;
    margin-bottom: 1px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-img {
      display: block;
    }
    &-name {
      @include fontRegular14;
      color: #363636;
    }
  }

  &__country-select {
    padding: 10px;
    margin-top: 5px;
  }
}
