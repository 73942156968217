@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';

.privacy-policy {
  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__title {
    @include fontRegular14;
  }

  &__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }

  &__btn {
    @include fontSemibold14;
    color: $accent-main;
    padding: 12px 16px;
    background: $accent-light;
    border-radius: 12px;
    

    &--disable {
      color: $text-light;
    }
    
    &--colorfull {
      background: $accent-main;
      color: $white;
    }
  }

  &__text-value {
    max-height: 55vh;
    overflow-y: scroll;
    align-items: center;
    color: $text-dark;
    @include fontRegular16;
  }
}