@import '../../../styles/utils/variables';

.Backdrop {
  backdrop-filter: blur(1.5px);
  background: rgba(58, 5, 65, 0.05);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &--higherZIndex {
    z-index: 6000;
  }

  &--mobile {
    top: 48px;
    height: calc(100% - 48px);
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: $accent-light;
  }

  &::-webkit-scrollbar:hover {
    background-color: $accent-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #791186;
    border-radius: 10px;
  }
}

.ModalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  width: fit-content;
  // height: fit-content;
  max-height: 90%;

  &--higher {
    top: 45%;
    @media screen and (max-width: 1100px) {
      // width: 325px;
    }
  }

  &--mobile {
    display: flex;
    min-height: 100%;
    max-height: fit-content;
    width: 100%;
    height: 100%;
  }

  &--unset {
    position: unset;
    transform: none;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar:hover {
    background-color: $accent-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $accent-main;
    border-radius: 10px;
  }
}

.ModalInner {
  padding-bottom: 0px;
  @media screen and (min-width: 1100px) {
    padding-bottom: 40px;
  }

  &--mobile {
    width: 100%;
    min-height: 100%;
    padding-bottom: 0px;
  }
}
