@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

video::-webkit-media-controls {
  visibility: hidden;
}

video::-webkit-media-controls-enclosure {
  visibility: visible;
}

.spiner {
  z-index: 20;
  position: absolute;
  top: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  max-width: 669px;
  max-height: 695px;
}

.spiner-content {
  position: absolute;
  border: 4px solid $accent-light;
  border-top: 4px solid $accent-main;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.media-view {
  height: calc(100vh - 48px);
  width: 100vw;
  position: fixed;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  background: $tags;
  z-index: 1000;
  justify-content: center;

  @media screen and (min-width: 1100px) {
    height: 100%;
    top: 60px;
    z-index: 100;
  }

  &__wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    height: calc(100vh - 48px);
    overflow-y: auto;

    @media screen and (min-width: 500px) and (max-width: 1100px) {
      padding: 0 40px;
      max-width: none;
    }

    @media screen and (min-width: 1100px) {
      width: 100%;
      max-width: none;
      height: calc(100vh - 60px);
    }
  }

  &__inner {
    // max-width: 1368px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 1100px) {
      padding: 20px 0;
      width: auto;
      height: 775px;
      max-width: 1368px;
      flex-direction: row;
    }
  }

  &__media {
    width: 100%;
    max-width: 500px;
    background: $text-dark;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 500px) and (max-width: 1100px) {
      max-width: none;
    }

    @media screen and (min-width: 1100px) {
      width: 900px;
      max-width: none;
    }
  }

  &__post {
    margin: 0 auto;
    padding: 16px 16px 180px;
    width: 100%;
    max-width: 500px;
    background: $white;
    border-radius: 8px;

    & .comments-container {
      padding: 8px 0px;
      max-width: 500px;

      & .comments-wrapper {
        margin-left: 8px !important;
      }

      & .comments-MoreHorizIcon-wrapper {
        margin-left: 8px !important;
      }
    }

    & .subcomments-container {
      padding: 8px 0px;

      & .subcomments-wrapper {
        margin-left: 8px !important;
      }

      & .subcomments-MoreHorizIcon-wrapper {
        margin-left: 8px !important;
      }

      & .subcomments-likes-text-wrapper {
        margin-right: 12px;

        & .subcomments-likes-text {
          margin-left: 2px;
        }
      }
    }

    @media screen and (min-width: 500px) and (max-width: 1100px) {
      max-width: none;
    }

    @media screen and (min-width: 1100px) {
      margin: none;
      padding: 15px;
      padding-right: 4px;
      width: 444px;
    }

    &-scroll {
      @media screen and (min-width: 1100px) {
        height: 100%;
        overflow-y: auto;
        padding-right: 8px;

        &::-webkit-scrollbar {
          width: 12px;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    position: relative;
    gap: 12px;

    &-avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }

    &-name {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }

    &-date {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $text-middle;
    }

    &-btn {
      top: 24px;
      right: 24px;
      position: absolute;
      width: 24px;
      height: 24px;
      box-shadow: 0px 0.75px 3px rgba(91, 29, 114, 0.1);
      border-radius: 50%;
      color: $text-middle;
      display: none;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: $accent-main;
        background: $accent-light;
      }

      & svg {
        width: 18px;
        height: 18px;
        color: $white;

        @media screen and (min-width: 1100px) {
          color: $text-dark;
        }
      }

      @media screen and (min-width: 1100px) {
        top: 0px;
        right: 0px;
        display: flex;
        background: $white;
      }
    }
  }

  &__mobile-delete-btn {
    display: flex;

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }

  &__descr {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    width: 100%;
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__close {
    top: 24px;
    left: 24px;
    position: absolute;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;

    &:hover {
      & svg {
        color: $accent-main;
        transform: rotate(180deg);
      }
    }

    & svg {
      transition: all 0.3s;
      color: $white;

      @media screen and (min-width: 1100px) {
        top: 10px;
        left: 7px;
        color: $text-middle;
      }
    }

    @media screen and (min-width: 1100px) {
      background: $white;
    }
  }

  &__arrow-left,
  &__arrow-right {
    width: 24px;
    height: 24px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: none;
    transition: all 0.3s;
    color: $white;

    &:hover {
      background: none;

      @media screen and (min-width: 1100px) {
        background: $accent-light;
      }

      & svg {
        color: $white;

        @media screen and (min-width: 1100px) {
          color: $accent-main;
        }
      }
    }

    & svg {
      height: 40px;
      width: 40px;
      transition: all 0.3s;

      @media screen and (min-width: 1100px) {
        height: auto;
        width: auto;
      }
    }

    @media screen and (min-width: 1100px) {
      color: $text-dark;
      background: $accent-light;
    }
  }

  &__arrow-left {
    left: 30px;

    @media screen and (min-width: 1100px) {
      left: 46px;
    }
  }

  &__arrow-right {
    right: 30px;

    @media screen and (min-width: 1100px) {
      right: 46px;
    }
  }

  &__media-wrapper {
    width: 100%;
    height: calc(100vh - 48px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $text-dark;

    @media screen and (min-width: 1100px) {
      width: 668px;
      height: 695px;
      background-color: #000;
    }
  }

  &__img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

    @media screen and (min-width: 1100px) {
      max-width: 668px;
      max-height: 695px;
    }
  }

  &__video {
    object-fit: contain;
    max-width: 100%;
    max-height: calc(100vh - 58px);

    @media screen and (min-width: 1100px) {
      height: 100%;
      max-width: 668px;
      max-height: 695px;
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    overflow: hidden;
  }

  &__time {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $white;
    position: absolute;
    bottom: 24px;
    right: 140px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 35px;
    padding-bottom: 4px;
    border-bottom: 1px solid $stroke;
    margin-bottom: 12px;

    @media screen and (min-width: 1100px) {
      justify-content: space-between;
    }

    &>button {
      @media screen and (min-width: 1100px) {
        margin: 0 64px;
      }
    }
  }

  .post__set-reactions {
    @media screen and (min-width: 1100px) {
      display: flex;
      flex-flow: row wrap;
      border-radius: 8px;
      box-shadow: $shadow;

      >.post__set-reaction-container {
        min-width: 132px;
        max-width: 132px;
        padding: 4px;
      }
    }
  }

  .post__set-reaction-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .post__cooperation {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid $stroke;
    margin-bottom: 4px;
  }

  .post__tag-comment-share {
    height: 18px;
  }

  .post__write-comment-input-container {
    & svg {
      width: 16px;
      height: 16px;
      color: $text-middle;
    }
  }
}

.shareModal {
  &-line {
    width: 100%;
    height: 1px;
    background: $stroke;
  }

  &-descr {
    margin-top: 16px;

    & .customTextarea {
      margin-bottom: 12px;
    }

    & textarea {
      height: 133px;
    }
  }

  &-post {
    margin-top: 12px;
    padding: 0px 24px;
    background: $bg;
    border: 1px solid $stroke;
    border-radius: 8px 8px 0px 0px;

    & .media-view__header {
      margin-bottom: 12px;
      margin-top: 8px;
    }

    &__img {
      width: 177px;
      height: 240px;
      border-radius: 8px;
      margin-bottom: 8px;
      object-fit: cover;
    }

    &__videoWrapper {
      position: relative;
      margin-bottom: 6px;
    }

    &__video {
      width: 177px;
      height: 240px;
      border-radius: 8px;
      object-fit: cover;
    }

    &__videoDuration {
      position: absolute;
      color: $white;
      bottom: 8px;
      left: 136px;
    }
  }
}