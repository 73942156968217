@import '../../../styles/utils/variables';

.accountSettings {
  height: 100%;
  width: 100%;
  background: $white;

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 16px;
  }

  &-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &__selects {
      display: flex;
      flex-direction: column;
      margin-bottom: 4px;

      & .select:first-child {
        margin-right: 0;
      }
    }

    &__wrap {
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &-list {
    &-item {
      margin-bottom: 4px;
    }

    &--small {
      margin-bottom: 24px;
    }

    &__other {
      display: flex;
      flex-direction: column;
      // align-items: center;

      & > label {
        margin-right: 8px;
        margin-bottom: 6px;
      }
    }
  }

  &-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__subtitle {
      margin-top: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $text-middle;
    }

    &__wrap {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;

      @media screen and (min-width: 1100px) {
        width: max-content;
      }
    }

    &--button-all {
      display: flex;
      flex-direction: column;
    }
  }

  &-chips {
    margin-bottom: 8px;

    & div {
      margin-right: 16px;
      margin-bottom: 16px;
      margin-bottom: 16px;
    }
  }

  &-photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 12px;

    & > label {
      margin-right: 8px;
      margin-bottom: 8px;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-tap-highlight-color: transparent;
    }
  }

  &-wrapper {
    margin-bottom: 12px;
  }

  &-upload {
    display: flex;
    margin-bottom: 24px;

    &__img {
      width: 100px;
      height: 100px;
      border: 1px solid $text-light;
      background: $accent-light;
      border-radius: 50%;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > svg {
        color: $text-light;
        pointer-events: none;
      }

      &--drag {
        border: 1px dashed $accent-main;
        background: #78118631;

        & > svg {
          color: $accent-main;
        }
      }
    }

    &__avatar {
      border: 1px solid $text-light;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 16px;
    }

    &__text {
      width: 130px;
    }

    &__space {
      width: 20px;
      display: inline-block;
    }

    &__column {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }

    &__row {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 7px;

      & label {
        margin-right: 0px;
        margin-top: 12px;
        margin-bottom: 12px;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
      }
    }

    & span {
      margin-top: 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $text-middle;
    }
  }

  &-bg {
    border-radius: 12px;
    height: 100%;
    color: $text-middle;
    border: 1px dashed $text-light;
    background: $accent-light;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    position: relative;
    overflow: hidden;

    &--drag {
      border: 1px dashed $accent-hover;
      background: #f7ecff;
    }

    &--error {
      border: 1px dashed $accent-hover;
      background: #ffecec;
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
    }

    &__btns {
      display: flex;
    }

    &__error {
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      position: absolute;
      display: flex;
      background: #ffc8c8;
      align-items: center;
      justify-content: center;
      color: #b00202;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      height: 16px;

      & > svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    & > svg {
      color: $text-light;
    }

    & > label {
      margin: 4px 0;
    }

    &__wrapper {
      height: 215px;
      position: relative;

      & > img {
        border-radius: 12px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &:hover {
        .accountSettings-bg__btns {
          opacity: 1;
          pointer-events: unset;
        }
      }
    }

    &__btns {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      z-index: 5;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s;

      & > label {
        margin-right: 10px;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    padding: 24px;
    width: 100%;
    box-shadow: 0px 4px 15px #f2f2f2;
    border-radius: 12px;
    margin-bottom: 24px;

    & > div:first-child {
      margin-right: 16px;
    }

    &-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      margin-bottom: 24px;

      &.privacy {
        margin-bottom: 8px;
      }
    }

    &-subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 24px;
    }

    &-footer {
      margin-top: 24px;

      & > button:first-child {
        margin-right: 16px;
      }
    }

    &-wrapper {
      margin-bottom: 0;
    }

    &-header {
      text-align: center;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      padding-bottom: 24px;
      margin-bottom: 30px;
      border-bottom: 1px solid $stroke;

      &__accent {
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        color: $accent-main;
      }
    }

    &-block {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;

      & > div:first-child {
        margin-right: 16px;
      }

      &__selects {
        display: flex;
        flex-direction: row;
        margin-bottom: 4px;

        & .select:first-child {
          margin-right: 16px;
        }
      }

      &__wrap {
        margin-top: 0;
      }
    }

    &-list {
      & > label {
        margin-bottom: 4px;
      }

      &--small {
        margin-bottom: 24px;
      }

      margin-bottom: 30px;

      &__other {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;

        & > label {
          align-self: center;
          margin-right: 8px;
          margin-bottom: 0;
        }
      }
    }

    &-bold {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      &__subtitle {
        margin-top: 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $text-middle;
      }

      &--button-all {
        align-items: center;
        display: flex;
        flex-direction: row;
      }
    }

    &-chips {
      margin-bottom: 8px;

      & div {
        margin-right: 16px;
        margin-bottom: 16px;
      }
    }

    &-photos {
      display: flex;
      margin-bottom: 12px;

      & > label {
        margin-right: 16px;
      }
    }

    &-upload {
      display: flex;
      margin-bottom: 24px;

      &__img {
        width: 110px;
        height: 110px;
        border: 1px solid $text-light;
        background: #fbf5ff;
        border-radius: 50%;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
          color: $text-light;
          pointer-events: none;
        }

        &--drag {
          border: 1px dashed $accent-main;
          background: #78118631;

          & > svg {
            color: $accent-main;
          }
        }
      }

      &__avatar {
        border: 1px solid $text-light;
        width: 110px;
        height: 110px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 16px;
      }

      &__column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 160px;
      }

      &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        & label {
          margin-right: 0px;
          margin-top: 12px;
          margin-bottom: 12px;
        }
      }

      & span {
        margin-top: 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $text-middle;
      }
    }

    &-bg {
      border-radius: 12px;
      height: 100%;
      color: $text-middle;
      border: 1px dashed $text-light;
      background: $accent-light;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      position: relative;
      overflow: hidden;

      &--drag {
        border: 1px dashed $accent-hover;
        background: #f7ecff;
      }

      &--error {
        border: 1px dashed $accent-hover;
        background: #ffecec;
      }

      &__btns {
        display: flex;
      }

      &__error {
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        position: absolute;
        display: flex;
        background: #ffc8c8;
        align-items: center;
        justify-content: center;
        color: #b00202;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        height: 16px;

        & > svg {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }

      & > svg {
        color: $text-light;
      }

      & > label {
        margin: 4px 0;
      }

      &__wrapper {
        height: 215px;
        position: relative;

        & > img {
          border-radius: 12px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        &:hover {
          .accountSettings-bg__btns {
            opacity: 1;
            pointer-events: unset;
          }
        }
      }

      &__btns {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s;

        & > label {
          margin-right: 10px;
        }
      }
    }
  }

  &-footer {
    margin-top: 24px;

    & > button:first-child {
      margin-right: 16px;
    }
  }

  &-header {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 24px;
    margin-bottom: 30px;
    border-bottom: 1px solid $stroke;

    &__accent {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: $accent-main;
    }
  }

  &__interest-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6c757d;
  }
}
