@import '../../../styles/utils/variables';

.avatarRadio {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  box-shadow: 0px 0px 6px 2px rgba(48, 6, 53, 0.05);
  // transition: all .3s;
  overflow: hidden;

  &-img {
    width: 50px;
    height: 50px;
    border: 1px solid transparent;
    border-radius: 50%;
  }

  // transition: all .3s;
  &-img {
    width: 50px;
    height: 50px;
    border: 1px solid transparent;
    border-radius: 50%;
  }
  &-check {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 15px;
    height: 15px;
    background: $green;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      color: $white;
      width: 10px;
      height: 10px;
    }
  }

  &--selected {
    border-color: $bg;
    max-width: 60px;
    max-height: 60px;
    box-shadow: 0px 0px 6px 2px rgba(48, 6, 53, 0.25) !important;
    overflow: visible;
  }

  &:hover {
    opacity: 0.9;
    box-shadow: 0px 0px 6px 2px rgba(48, 6, 53, 0.1);
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
