@import '../../styles/utils/mixin';
@import '../../styles/utils/variables';

.Notifications {
  &__activityData {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    @include fontSemibold16;
    color: $accent-main;
    background-color: #fdeeff;
    width: 100%;
  }

  &__items {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid $stroke;

    &>div {
      display: flex;
      flex-direction: row;
    }
  }

  &__description {
    line-height: 22px;
    padding-right: 12px;
    font-size: 16px;

    @media screen and (max-width: 1100px) {
      // height: 44px;
      overflow: hidden;
    }

    &--bold {
      @include fontSemibold16;
      color: $text-dark;
    }

    &--link {
      @include fontSemibold16;
      color: $accent-main;
      cursor: pointer;
    }
  }

  &__avatar {
    width: 64px;
    height: 64px;
    object-fit: cover;
    margin: 0 12px;
    border-radius: 100%;

    @media screen and (max-width: 1100px) {
      width: 44px;
      height: 44px;
      min-width: 44px;
      min-height: 44px;
    }
  }

  &__reaction-container {
    display: flex;
    position: absolute;
    transform: translateX(160%) translateY(70%);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid $accent-main;
    background-color: $accent-light;

    @media screen and (max-width: 1100px) {
      width: 20px;
      height: 20px;
      transform: translateX(200%) translateY(80%);
    }
  }

  &__reaction {
    display: flex;
    margin: auto;
    width: 20px;
    height: 20px;

    @media screen and (max-width: 1100px) {
      width: 14px;
      height: 14px;
    }
  }

  &__username {
    &:hover {
      cursor: pointer;
      color: $accent-main;
    }
    &:active {
      color: $accent-hover;
    }
  }
}
