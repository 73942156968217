.burger {
  left: -100vw;
  top: 48px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
  z-index: 2000;
  transition: 0.2s linear;

  &--visible {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100vw);

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }

  &__header-navigation {
    padding: 10px 0;
    height: 153px !important;
    min-height: 153px !important;
    width: 100%;
    position: static !important;
    display: flex !important;
    left: initial !important;
    flex-direction: column;
    justify-content: space-between;
    transform: translateX(0) !important;
    align-items: flex-start !important;
    cursor: pointer;
  }

  &__navigation-item {
    padding: 0 24px;
    width: 100%;
    height: 38px !important;
    line-height: normal !important;
    background-color: none;
    transition: bacground-color 0.2s linear;

    &:hover {
      background-color: #fadcff;
    }
  }

  &__navigation-link {
    height: 100%;
    width: 100%;
  }

  & .nav-side {
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & .nav-side__section {
    padding: 0;
    width: 100%;

    @media screen and (max-width: 1100px) {
      align-self: flex-start;
      border-radius: 0;
    }
  }

  &
    > .nav-side
    > .nav-side__section
    > .nav-side__section-info
    > .accordion-main
    > .accordion-main-rows
    > .MuiPaper-root
    > .MuiButtonBase-root {
    padding-left: 24px;

    &
      > .MuiAccordionSummary-content
      > .accordion-main__title-container
      > .MuiTypography-root {
      // padding-right: 45px;
    }
  }

  &
    > .nav-side
    > .nav-side__section
    > .nav-side__section-info
    > .accordion-main
    > .accordion-main-rows
    > .MuiPaper-root
    > .Mui-expanded {
    background-color: #fbf5ff;
  }

  & .accordion-main__item {
    padding-left: 60px;
  }

  & .nav-side__title {
    padding-left: 24px;
    font-size: 13px;
    color: #6c757d;

    @media screen and (max-width: 1100px) {
      display: none;
    }
  }

  &__relax-room {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-image: url('../../images/palmtree2.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
