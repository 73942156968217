@import '../../styles/utils/variables';

.aside {
  max-width: 210px;
  min-width: 210px;
  display: none;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: fixed;

  @media screen and (min-width: 1100px) {
    display: flex;
    margin-left: 899px;
  }

  @media screen and (min-width: 1440px) {
    display: flex;
    margin-left: 1150px;
  }

  &__section {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 8px;
    width: 100%;

    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 275px);
  }

  &__section-main-title-container {
    margin-bottom: 12px;
    padding: 0 0 12px;
    position: relative;
    border-bottom: 1px solid $stroke;
  }

  &__section-main-title {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: $accent-main;
  }

  &__section-main-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    & svg {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 18px;
      height: 18px;
      color: $accent-main;
    }
  }

  &__section-main-title-container {
    padding: 0 12px 12px;
  }

  &__section-news-card {
    padding: 0 16px;
    padding-bottom: 12px;
    margin-bottom: 6px;
    display: block;
    border-bottom: 1px solid #e9e9e9;

    // &:hover .aside__section-news-poster{
    //   transform: scale(1.2);
    // }
  }

  &__section-title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $text-dark;
  }

  &__section-poster-container {
    height: 104px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 8px;

    @media screen and (max-width: 1100px) {
      height: 184px;
    }
  }

  &__section-news-poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.1s linear;
  }

  &__section-details {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 19px;
    color:  #363636;
  }

  &__section-link {
    width: 100%;
    padding: 4px 0px;
    font-size: 12px;
    line-height: 16px;
    color: $accent-main;
    background-color: $tags;
    border-radius: 4px;
    transition: background-color 0.3s linear;

    &:hover {
      background-color: #d2bfd5;
    }
  }
}
