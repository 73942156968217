@import './styles/utils/normalize';
@import './styles/utils/variables';
@import './styles/utils/mixin';

// === ... ===
// white-space: nowrap;
// overflow: hidden;
// text-overflow: ellipsis;

// ========================================================
:root {
  --toastify-color-progress-info: #791186 !important;
  --toastify-color-progress-success: #28a745;
  --toastify-color-progress-error: #fd2551;
}

.tippy-box[data-theme~='middle-gray'] {
  background-color: $text-middle;
  font-family: inherit;
  font-size: 20px;
  font-weight: 400;
}

.tippy-box[data-theme~='middle-gray'][data-placement^='right']>.tippy-arrow::before {
  border-right-color: $text-middle;
}

.tippy-box[data-theme~='middle-gray'][data-placement^='left']>.tippy-arrow::before {
  border-left-color: $text-middle;
}

.tippy-box[data-theme~='middle-gray'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: $text-middle;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
}

.tippy-box[data-theme~='middle-gray'][data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: $text-middle;
}

// for Accessibility Widget
// .uiiw {
  // position: relative;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: -3px;
  //   left: -3px;
  //   width: 46px;
  //   height: 46px;
  //   border-radius: 50%;
  //   background-color: #6F1C81;
  // }
  // &::after {
  //   content: "";
  //   background: url(./images/eyeglasses.svg);
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   width: 70px;
  //   height: 65px;
  //   position: absolute;
  //   bottom: -15px;
  //   left: -15px;
  // }
// }


body .uwy.userway_p5 .uai {
  position: relative;

  &::after {
    content: "";
    background: url(./images/eyeglasses.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 70px;
    height: 65px;
    position: absolute;
    bottom: -15px;
    left: -15px;
  }
}

.uwy .uai img:not(.check_on),
body .uwy .uai img:not(.check_on) {
  display: none !important;
}
