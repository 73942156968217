@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.PlayGame {
  &__container {
    width: 100vw;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;

    @media screen and (min-width: 1100px) {
      width: 1080px;
      height: 690px;
      padding: 16px;
    }

    > .MuiSvgIcon-root.arrowBack {
      position: absolute;
      color: $text-middle;
      left: 16px;
      top: -34px;
      transition: $transition;
      cursor: pointer;

      &:hover,
      &:active {
        color: $accent-main;
      }
    }
  }

  &__iframe {
    width: 100%;
    height: 600px;
    border-radius: 12px;
    border: 1px solid $accent-light;
  }
}
