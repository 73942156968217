.container-root {
  background: rgba (212, 212, 212, 0.5);
  max-width: 100vw;
  height: 100vh;
  position: relative;

  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  // min-width: 375px;

  // @media screen and (min-width: 1100px) {
  //   height: 100vh;
  // }
}

.container-root-bg{
  background-image: url('../../../images/auth-bg.png');
  background-repeat: no-repeat;                    /* Prevents the image from repeating */
  background-position: center;                     /* Centers the image horizontally */
  background-size: auto 100%;
  @media screen and (max-width: 1100px) {
    background-image: url('../../../images/auth-bg-mobile.png');
  }
}

.ellipse1 {
  position: absolute;
  bottom: -270px;
  left: 0;
  z-index: -1;
  @media screen and (min-width: 1100px) {
    bottom: 0px;
  }
}

.ellipse2 {
  position: absolute;
  top: 29px;
  left: 0;
  z-index: -1;
}

.ellipse3 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.ellipse4 {
  position: absolute;
  bottom: -270px;
  right: 0;
  z-index: -1;
  @media screen and (min-width: 1100px) {
    bottom: 0px;
  }
}

.container-content {
  height: 100%;
}
