.notificationSettings {
  padding-bottom: 84px;

  @media screen and (min-width: 1100px) {
    padding-bottom: 24px;
  }

  &-list {
    display: flex;
    flex-flow: column nowrap;

    > label {
      width: fit-content;
      margin-bottom: 4px;
    }
  }
}
