.textButton {
  transition: all 0.3s;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #363636;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;

  @media screen and (min-width: 1100px) {
    color: #791186;
  }

  &:hover {
    color: #620c6d;

    & path {
      fill: #620c6d;
    }
  }

  &[disabled] {
    color: #a7a7a7;
    pointer-events: none;

    & path {
      fill: #a7a7a7;
    }
  }

  & svg {
    margin-right: 8px;

    & path {
      transition: all 0.3s;
    }
  }
}
