@import '../../styles/utils/variables';

.write-comment {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  column-gap: 12px;

  &-avatar-container {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &-avatar {
    object-fit: cover;
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: inherit;
    height: inherit;
    min-width: inherit;
    min-height: inherit;
    transform: translate(-50%, 50%);
    border-radius: 50%;
  }

  &-input-container {
    position: relative;
    flex-grow: 1;

    &>.MuiSvgIcon-root {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-tap-highlight-color: transparent;
    }
  }

  &-input {
    padding: 11px 50px 11px 16px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #a7a7a7;
    border: 1px solid #e9e9e9;
    border-radius: 8px;

    &:focus {
      outline: 1px solid #fadcff;
      color: #791186;
      background-color: #fbf5ff;
    }

    &::placeholder {
      color: #a7a7a7;
    }
  }

  &-send {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &:hover {
      fill: #620c6d;
    }
  }
}


.showComments {
  &-show-comments-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    padding-top: 24px;
    color: #791186;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;

    &--disabled {
      color: $text-middle;
      pointer-events: none;
      cursor: auto;
    }

    &.title {
      padding-top: 0;
    }
  }
}

.comment-bottom-btn {
  display: flex;
  flex-direction: column-reverse;

  &>button{
    margin-bottom: 12px;
  }

  @media screen and (min-width: 1100px) {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    &>button{
      margin-bottom: 0px;
    }
  }
}