@import '../../styles/utils/variables';

.sidebar {
  min-width: 220px;
  margin-right: 24px;

  &-item {
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    padding: 12px 16px;
    border-radius: 4px;
    cursor: pointer;

    &--active{
      background-color: $accent-main;
      color: $white;
    }
  }

  @media screen and (max-width: 1100px) {
    margin-right: 0;

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 24px;

      &--mob-deactivate {
        justify-content: center;
        padding-left: 0;
        margin: 0 24px;
        text-align: center;
        background: $accent-light;
        color: $accent-main;
        border-radius: 12px;
      }

      &__icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $text-light;

        & svg {
          font-size: 18px;
        }
      }
    }
  }
}

