@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixin';

.InterestArea {
  @include fontSemibold14;
  color: $accent-main;
  transition: $transition;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding: 7px 0;
  height: fit-content;

  &:hover {
    color: $accent-hover;
  }

  &:disabled {
    @include fontRegular14;
    color: $text-light;
  }
}