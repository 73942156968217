@import '../../styles/utils/mixin';

.main-page__inner.search-filters {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
}

.filters-invisible {
  display: none;
}
.filters-visible {
  display: block;
}
//     top: 0px;
//     left: 0;
//     position: absolute;
//     padding: 8px 24px;
//     width: 375px;
//     height: 182px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     background-color: #fff;
//     z-index: 1000;
//     transform: translateY(48px);
//     transition: transform 0.2s ease-in;

//     @media screen and (min-width: 1100px) {
//       position: static;
//     }
//   }
// }

.search-wall {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (min-width: 1100px) {
    margin: 0 20px;
    min-width: 500px;
    max-width: 610px;
    margin-left: 282px !important;
  }

  @media screen and (min-width: 1440px) {
    margin: 0 20px;
    min-width: 785px;
    max-width: 785px;
    margin-left: 344px !important;
  }

  h2 {
    @include fontSemibold16;
    text-align: center;
    color: #363636;
    margin-top: 2px;

    @media screen and (min-width: 1100px) {
      text-align: start;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }
  }

  h3 {
    @include fontRegular14;
    text-align: center;
    color: #363636;
    margin-top: 2px;
    @media screen and (min-width: 1100px) {
      margin-top: 2px;
      text-align: start;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
  }
}
