.main-container {
  margin: 0 auto;
  max-width: 1100px;

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }
  
}

.bdrad8 {
  border-radius: 8px;
}
