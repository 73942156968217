@import '../../styles/utils/variables';
@import '../../styles/utils/mixin';

.faq-page {
  position: relative;
  left: 0;
  top: 0;
  padding: 24px 24px 36px;
  width: 100%;
  background-color: $white;
  max-width: 100vw;
  min-height: 100vh;

  &__title {
    @include fontSemibold22;
    margin-bottom: 4px;
  }

  &__subtitle {
    @include fontRegular18;
  }

  &__container {
    position: fixed;
    display: block;
    top: 48px;
    width: 100vw;
    height: 100%;
    z-index: 998;
    background: $white;
    transition: all 0.3s;
  }

  &__inner {
    margin-top: 0;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__mobile-title {
    padding: 12px 24px;
  }

  &__button-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    @include fontSemibold22;
    text-align: left;
    color: $text-dark;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;

    &-text {
      color: $text-dark;
    }
  }

  &__back-icon {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 16px;
    }
  }

  @media screen and (min-width: 1100px) {
    width: 1116px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
    background: transparent;
    box-shadow: 0px 4px 15px #f2f2f2;
    height: max-content;
    min-height: 0;
    // border-radius: 12px;
    box-sizing: border-box;

    &__inner {
      display: flex;
      margin-top: 24px;
    }

    &__container {
      position: relative;
    }
  }
}

.faq-block {

  &__link {
    display: block;
    text-align: center;
    width: fit-content;
    background: $accent-main;
    width: 100%;
    border: none;
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $white;
    padding: 12px 16px;
    transition: all .3s;
    cursor: pointer;

    &--bigPadding {
      padding: 12px 49px;
    }

    &:focus,
    &:hover {
      background-color: $accent-hover;
    }

    &[disabled] {
      background: $stroke;
      pointer-events: none;
    }

  }

  @media screen and (min-width: 1100px) {
    &__link {
      width: 150px;
    }
  }
}

.faq {
  padding: 24px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 15px #F2F2F2;
  border-radius: 12px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  &-block {
    display: flex;
    flex-direction: column;
  }

  &-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid $stroke;
    cursor: pointer;

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  &-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-question-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: $text-dark;
  }

  &-question-icon {
    color: $text-middle;
  }

  &-details-container {
    overflow: hidden;
    height: 0;
    transition: 0.3s linear;
  }

  &-details {
    max-width: 972px;
    line-height: 130%;
    // overflow: hidden;
  }

  &-text {
    margin-bottom: 22px;
  }

  &-contact-us-row {
    margin-bottom: 24px;
    max-width: 846px;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:nth-child(2) {
      margin-bottom: 16px;
    }
  }

  &-contact-us-inp-container {
    margin-right: 16px;
  }

  &-contact-us-submit {
    right: 0;
    bottom: -38px;
  }

  &-contact-us-submit-text {
    margin-right: 8px;
    font-weight: 600;
    font-size: 16px;
    color: $text-dark;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &-contact-us-attached {
    margin-top: -25px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
  }

  &-contact-us-attached-row {
    margin-bottom: 8px;
    padding: 6px 0 6px 6px;
    max-width: 255px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bg;
    color: $text-middle;
    border-radius: 4px;
    overflow: hidden;
    height: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;


    transition: background-color 0.3s linear;


    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #efeeee;
    }
  }

  & .accordion-main__title-container {
    @media screen and (min-width: 1100px) {
      width: 972px;
    }
  }
}