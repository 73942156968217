@import '../../../styles/utils/mixin';
@import '../../../styles/utils/variables';

.Messages {
  display: flex;
  flex-direction: column;
  width: 42vw;
  background-color: $white;
  margin-right: 45px;
  height: 100%;

  @media (max-width: 1100px) {
    width: 100vw;
    margin-right: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 48px;
    padding: 13px 24px;
    box-sizing: border-box;
    border-bottom: 1px solid $stroke;
    background-color: $accent-light;

    &--ar {
      flex-direction: row-reverse;
    }
  }

  &__mobile-open-btn {
    display: none;

    @media (max-width: 750px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }
  }

  &__name {
    @include fontSemibold16;
  }

  &__status-indicator {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 3px solid $white;
    box-sizing: border-box;
  }

  &__main-wrapper {
    height: 80vh;
    display: flex;
    flex-direction: column;

    @media (max-width: 750px) {
      height: 75vh;
    }
  }

  &__main {
    flex: 1;
    border-bottom: 1px solid $accent-main;
    width: 100%;
    padding-right: 6px;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__wrapper {
    padding-bottom: 24px;
  }

  &__date {
    display: flex;
    flex-direction: row;
    align-items: center;

    &>p {
      padding: 0 10px;
      box-sizing: border-box;
      width: 150px;
      text-align: center;
      color: $text-light;
      @include fontRegular14;
    }
  }

  &__date-line {
    width: 100%;
    height: 1px;
    background-color: $stroke;

    &--margin {
      margin-top: 24px;
    }
  }

  &__empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    @media (max-width: 1100px) {
      box-sizing: border-box;
      padding: 0 25px;
    }

    &>p {
      text-align: center;
      color: $text-middle;
      @include fontSemibold14;
    }
  }
}